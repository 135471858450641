import "react-tooltip/dist/react-tooltip.css";

import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as ScaleIcon } from "@/assets/scale-icon.svg";

import { Button } from "../../../../lib/button/Button";
import { tooltipStyle } from "../../../../lib/tooltip/tooltipStyle";
import { theme } from "../../../../theme/theme";
import {
  DetailedGradingScaleModal,
  type DetailedGradingScaleModalRef,
} from "./DetailedGradingScaleModal";

interface Props {
  gradingScaleType: GradingScaleType;
}

export const DetailedGradingScale = ({ gradingScaleType }: Props) => {
  const gradingScaleModalRef = useRef<DetailedGradingScaleModalRef>(null);
  const { t } = useTranslation();

  const openGradeScale = (scaleType: GradingScaleType) => {
    gradingScaleModalRef.current?.display(scaleType);
  };

  return (
    <>
      <ScaleDetailsButton
        id="detailedGridButton"
        variant="text"
        hierarchy="secondary"
        size="md"
        disabled
        className="disabled"
        onClick={() => openGradeScale(gradingScaleType)}
      >
        {t("gradingScaleDetails")}
        <ScaleIcon />
      </ScaleDetailsButton>
      <Tooltip
        anchorSelect="#detailedGridButton"
        content={t("gradingScaleDetailsTooltip")}
        place="top-end"
        border={`1px solid ${theme.colors.gray[200]}`}
        style={{
          ...tooltipStyle,
          boxShadow: theme.shadowAndBlur.small,
          borderRadius: 12,
          color: theme.colors.gray[800],
        }}
      />
      <DetailedGradingScaleModal ref={gradingScaleModalRef} />
    </>
  );
};

const ScaleDetailsButton = styled(Button)`
  padding: 0 !important;
  width: auto;
  color: ${theme.colors.gray[600]} !important;

  &.disabled {
    cursor: not-allowed !important;
    color: ${theme.colors.gray[300]} !important;
    * {
      stroke: ${theme.colors.gray[300]} !important;
    }
  }
`;
