import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AudioPlayer } from "@/application/ui/lib/recording/components/AudioPlayer.tsx";
import { ControlButton } from "@/application/ui/lib/recording/components/AudioStyled.ts";
import { LoadingSpinner } from "@/application/ui/lib/spinner/LoadingSpinner.tsx";
import { useAudioUrl } from "@/application/ui/pages/Revision/components/useAudioUrl.ts";
import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as DeleteIcon } from "@/assets/delete-icon.svg";

interface Props {
  onDelete: () => void;
  assignmentId: string;
}
export const TeacherAudioPlayer = ({ onDelete, assignmentId }: Props) => {
  const { t } = useTranslation();
  const { audioUrl, isLoading } = useAudioUrl(assignmentId);
  if (audioUrl && !isLoading) {
    return (
      <>
        <AudioPlayer url={audioUrl} />

        <ControlButton onClick={onDelete}>
          <TrashIcon title={t("deleteAudio")} />
        </ControlButton>
      </>
    );
  }
  return <LoadingSpinner />;
};

const TrashIcon = styled(DeleteIcon)`
  color: ${theme.colors.grayBlue[500]};
`;
