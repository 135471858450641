import { DEMO_OUTSIDER_EMAIL_DOMAINS } from "../../ui/pages/Dashboard/DemoConstants";

export enum HomeroomRole {
  Teacher,
  Student,
  Admin,
}

export type HomeroomRelation = {
  id: string;
  name: string;
  role: HomeroomRole;
};

export interface LoggedUserProps {
  id: string;
  name: string;
  email: string;
  role: HomeroomRole;
  isDevelopper: boolean;
}

export abstract class LoggedUser {
  id: string;
  name: string;
  email: string;
  isDevelopper: boolean;

  protected constructor(props: LoggedUserProps) {
    this.id = props.id;
    this.name = props.name;
    this.email = props.email;
    this.isDevelopper = props.isDevelopper;
  }

  hasRole(role: HomeroomRole): boolean {
    return this.role === role;
  }

  isTeacher(): boolean {
    return this.role === HomeroomRole.Teacher;
  }

  isStudent(): boolean {
    return this.role === HomeroomRole.Student;
  }

  isAdmin(): boolean {
    return this.role === HomeroomRole.Admin;
  }

  isDomainOutsider(): boolean {
    return !DEMO_OUTSIDER_EMAIL_DOMAINS.some((x) =>
      this.email.toLowerCase().includes(x.toLowerCase()),
    );
  }

  abstract role: HomeroomRole;
}
