import styled from "styled-components";

import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";

import { theme } from "../theme/theme";

export const Page = styled.section`
  padding: 24px;
  flex: 1;
  display: flex;
  gap: 24px;
  flex-direction: column;
  background-color: ${theme.colors.gray[50]};
  overflow-x: hidden;
  overflow-y: auto;
  ${scrollbarStyle()}
`;
