import { DefaultTaskCard } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/DefaultTaskCard.tsx";
import type { ContextMenuProps } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/ContextMenu/ContextMenuFactory.tsx";

import { DraftTaskCard } from "./DraftTaskCard";
import { MaskedTaskCard } from "./MaskedTaskCard";

export const WritingTaskCard = (props: ContextMenuProps) => {
  if (props.writingTask.typeStatus === "masked") {
    return <MaskedTaskCard {...props} />;
  }
  if (props.writingTask.typeStatus === "draft") {
    return <DraftTaskCard {...props} />;
  }
  return <DefaultTaskCard {...props} />;
};
