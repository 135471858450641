import { useTranslation } from "react-i18next";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { ReactComponent as ListIcon } from "@/assets/list.svg";

import type { ContextMenuAction } from "../MenuAction";

export const SeeStudentsList = (
  writingTask: TeacherWritingTask,
  seeStudentsList: (task: TeacherWritingTask) => void,
): ContextMenuAction => {
  const { t } = useTranslation();

  return {
    label: t("seeStudentList"),
    children: (
      <>
        <ListIcon />
        {t("seeStudentList")}
      </>
    ),
    callback: () => seeStudentsList(writingTask),
  };
};
