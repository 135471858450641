import "react-loading-skeleton/dist/skeleton.css";

import { EvaluationCriterion } from "@api/grade/model/GradedCriteria";
import type { ValuedGrade } from "@emilia/backend/src/common/grade/model/ValuedGrade";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { LayoutContainer, Page } from "@/application/ui/assignment.layout.ts";
import { BottomChart } from "@/application/ui/pages/GradeReport/components/BottomChart.tsx";
import { useGrades } from "@/application/ui/pages/GradeReport/GradeStates/useGrades.ts";

import { CriteriaResultChart } from "./components/CriteriaResultChart";
import { InformationCard } from "./components/WritingTaskReportCard/InformationCard";
import { GradeReportByGroup } from "./GradeReportByGroup";
import { GradeReportByStudent } from "./GradeReportByStudent";
import { GradeReportFilters } from "./GradeReportFilters";
import { GradeReportHeader } from "./GradeReportHeader";
import { useGradedStudents } from "./hooks/useGradedStudents";
import { useGradeReport } from "./hooks/useGradeReport";
import { useWritingTasksGradeReport } from "./hooks/useWritingTasksGradeReport";

export type SortedGrades = {
  grade: ValuedGrade;
  criterion: EvaluationCriterion;
}[];

export const WritingTaskGradeReport = () => {
  const { homeroomId, writingTaskId } = useParams();
  const { writingTaskDashboard } = useWritingTasksGradeReport(homeroomId);
  const {
    gradeContextDto,
    updateContext,
    setTaskId,
    taskId,
    setStudent,
    student,
  } = useGrades();
  useEffect(() => {
    if (writingTaskId) setTaskId(writingTaskId);
  }, [setTaskId, writingTaskId]);

  const { gradedStudents } = useGradedStudents(taskId);

  const shouldUseStudentGradeReport =
    gradeContextDto.isStudentMode &&
    !!student?.id &&
    gradedStudents?.students.find((student) => student.id === student?.id);

  const gradeReport = useGradeReport(
    taskId,
    shouldUseStudentGradeReport ? student?.id : undefined,
  );

  if (!taskId || !homeroomId || !writingTaskDashboard) {
    return null;
  }

  const labelOrder = [
    EvaluationCriterion.ADAPTATION,
    EvaluationCriterion.COHERENCE,
    EvaluationCriterion.VOCABULARY,
    EvaluationCriterion.SYNTAX,
    EvaluationCriterion.ORTHOGRAPHY,
  ];

  const criterionOrderMap = new Map(
    labelOrder.map((criterion, index) => [criterion, index]),
  );

  const sortedGrades: SortedGrades =
    gradeReport?.grades.sort((a, b) => {
      const orderA = criterionOrderMap.get(a.criterion);
      const orderB = criterionOrderMap.get(b.criterion);
      return (orderA || 0) - (orderB || 0);
    }) ?? [];

  if (
    gradeContextDto.selectedCriteria !== "all" &&
    !sortedGrades
      .map((x) => x.criterion.toString())
      .includes(gradeContextDto.selectedCriteria)
  ) {
    updateContext({ criteria: "all" });
  }

  const selectStudent = (studentId: string) => {
    updateContext({ studentMode: true });
    setStudent(gradedStudents?.students.find((x) => x.id === studentId));
  };

  return (
    <LayoutContainer>
      <Page>
        <GradeReportHeader
          writingTaskDashboard={writingTaskDashboard}
          writingTaskId={writingTaskId ?? ""}
          homeroomId={homeroomId}
        />
        <MainContainer>
          <CenteredContainer>
            <GradeReportFilters
              gradedStudents={gradedStudents}
              sortedGrades={sortedGrades}
              selectStudent={selectStudent}
            />

            <SplitContainer>
              {!gradeContextDto.isStudentMode && (
                <InformationCard taskId={taskId} />
              )}
              <CriteriaResultChart
                sortedGrades={sortedGrades}
                average={gradeReport?.average}
                isLetterMode={gradeContextDto.isLetterMode}
                selectedCriteria={gradeContextDto.selectedCriteria}
              />
            </SplitContainer>

            {!gradeContextDto.isStudentMode && <GradeReportByGroup />}

            <BottomChart
              selectStudent={selectStudent}
              data-testId="bottomChart"
            />
          </CenteredContainer>
        </MainContainer>
        {gradeContextDto.isStudentMode && <GradeReportByStudent />}
      </Page>
    </LayoutContainer>
  );
};

const MainContainer = styled.div`
  flex: 1;
  padding: 40px;
  overflow-y: auto;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const SplitContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;
