import { useTranslation } from "react-i18next";

import { ReactComponent as PrinterIcon } from "@/assets/printer.svg";

import type { ContextMenuAction } from "../MenuAction";

export const Print = (
  onPrint: () => void,
  taskStatus: string,
): ContextMenuAction => {
  const { t } = useTranslation();
  return {
    label: t("teacherWritingTaskCardAction.print_assignments"),
    children: (
      <>
        <PrinterIcon />
        {t("teacherWritingTaskCardAction.print_assignments")}
      </>
    ),
    disabled: ![
      "ready_to_revise",
      "revision_in_progress",
      "ready_to_publish",
      "done",
    ].includes(taskStatus),
    callback: onPrint,
  };
};
