import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import { UserProvider } from "@/application/authentication/UserProvider.tsx";
import { CustomToastContainer } from "@/application/ui/lib/toast/CustomToastContainer.tsx";
import { StyledTooltip } from "@/application/ui/lib/tooltip/StyledTooltip.tsx";
import { routesConfig } from "@/application/ui/routing/routes.config.tsx";

import { theme } from "../../theme/theme";
import { AuthConfigProvider } from "./AuthConfigProvider";
import { EnvProvider } from "./EnvProvider";
import { MsalProvider } from "./MsalProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const GlobalStyle = createGlobalStyle`
  body {
    color: ${theme.colors.text.primary};
    ${theme.typography.md.regular};
  }
  
  * {
    color: inherit;
  }
`;

function App() {
  const router = createBrowserRouter(routesConfig);
  return (
    <ThemeProvider theme={theme}>
      <SkeletonTheme baseColor={theme.colors.gray[200]}>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <AppContainer>
            <StyledTooltip />
            <CustomToastContainer />
            <AuthConfigProvider>
              <MsalProvider>
                <EnvProvider>
                  <UserProvider>
                    <RouterProvider router={router} />
                  </UserProvider>
                </EnvProvider>
              </MsalProvider>
            </AuthConfigProvider>
          </AppContainer>
        </QueryClientProvider>
      </SkeletonTheme>
    </ThemeProvider>
  );
}

export default App;

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;
