import { useMemo } from "react";
import styled from "styled-components";

import type { ConsultationAssignment } from "@/application/domain/Assignment.ts";
import {
  Header,
  LayoutContainer,
  Page,
} from "@/application/ui/assignment.layout.ts";
import { AssignmentSection } from "@/application/ui/pages/Revision/AssignmentSection.tsx";
import { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { theme } from "../../theme/theme";
import { AssignmentsProvider } from "../Revision/RevisionStates/AssignmentsProvider";
import { StudentAssignmentSidebar } from "./StudentAssignmentSidebar";

type Props = {
  assignment: ConsultationAssignment;
};

export const StudentAssignmentPageContent = ({ assignment }: Props) => {
  const revisedAssignmentContentState = useMemo(
    () => RevisedAssignmentContentState.init(assignment),
    [assignment],
  );

  return (
    revisedAssignmentContentState && (
      <AssignmentsProvider>
        <LayoutContainer>
          <Page>
            <Header className="header">
              <WritingTaskInfo>
                <WritingTaskTitle>
                  {assignment?.writingTaskInformation.name}
                </WritingTaskTitle>
                <WritingTaskInstructions>
                  {assignment?.writingTaskInformation.instructions}
                </WritingTaskInstructions>
              </WritingTaskInfo>
            </Header>
            <AssignmentSection
              revisedAssignmentContentState={revisedAssignmentContentState}
              comments={assignment.comments}
            />
          </Page>
          <StudentAssignmentSidebar assignment={assignment} />
        </LayoutContainer>
      </AssignmentsProvider>
    )
  );
};

const WritingTaskInfo = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "16px 40px",
  gap: 8,
});

const WritingTaskTitle = styled.div`
  ${theme.typography.sm.medium}
`;

const WritingTaskInstructions = styled.div`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]}
`;
