import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { ReactComponent as TimeoutIcon } from "@/assets/timeout.svg";

import { theme } from "../../theme/theme";

export const NoGroupsPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Container>
        <TimeoutIcon />
        <TimeoutTitle>{t("noGroupsPage.title")}</TimeoutTitle>
        <SubTitle>{t("noGroupsPage.subtitle")}</SubTitle>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.gray[100]};
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  gap: 16px;
`;

const TimeoutTitle = styled(Title)`
  text-align: center;
`;

const SubTitle = styled.p`
  ${theme.typography.md.regular}
  display: inline;
  text-align: center;
`;
