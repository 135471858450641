/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { Page } from "@/application/ui/lib/Page.tsx";
import { Title } from "@/application/ui/lib/PageTitle.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { ReactComponent as FileIcon } from "@/assets/file-blank.svg";
import { ReactComponent as Plus } from "@/assets/plus.svg";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { IconButton } from "../../lib/button/IconButton";
import { theme } from "../../theme/theme";
import { InviteDemoClient } from "./client/InviteDemoClient";

export interface InviteDemoFieldValues {
  teachers: Blob[];
}

export const InviteDemoPage = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<InviteDemoFieldValues>();
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const { mutate: importUser } = useMutation(
    (data: InviteDemoFieldValues) => InviteDemoClient.inviteUsers(data),
    {
      onSuccess: () => {
        ToastService.showSuccess(t("importSuccessful"));
        removeFile();
      },
      onError: () => {
        ToastService.showError(t("importFailure"));
      },
    },
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFileName(file.name);
      setValue("teachers", [file]);
    }
  };

  const removeFile = () => {
    setSelectedFileName(null);
    setValue("teachers", []);
  };

  return (
    <Page>
      <Title>{t("demo.title")}</Title>

      <Form onSubmit={handleSubmit((data) => importUser(data))}>
        <Container>
          <FileSelector>
            <Row>
              <div>{t("demo.list")}</div>
              <label htmlFor="file-input">
                <PlusIcon />
              </label>
              <input
                id="file-input"
                {...register("teachers")}
                type="file"
                accept="text/csv"
                onChange={handleFileChange}
              />
            </Row>
            {selectedFileName && (
              <FileNameRow>
                <FileName>
                  <FileIcon />
                  {selectedFileName}
                </FileName>
                <IconButton
                  onClick={removeFile}
                  type="button"
                  size="sm"
                  hierarchy="neutral"
                  variant="text"
                >
                  <XCloseIcon />
                </IconButton>
              </FileNameRow>
            )}
          </FileSelector>
          <Centered>
            <Button
              width={90}
              type="submit"
              variant="contained"
              disabled={!selectedFileName}
            >
              {t("demo.import")}
            </Button>
          </Centered>
        </Container>
      </Form>
    </Page>
  );
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const PlusIcon = styled(Plus)`
  padding: 12px;
  background-color: ${theme.colors.primary[25]};
  border-radius: ${theme.radius.round};
  width: 20px;
  height: 20px;

  * {
    stroke: ${theme.colors.primary[700]};
  }
`;

const FileName = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 8px;
  ${theme.typography.xs.regular};
  color: ${theme.colors.gray[600]};
`;

const Row = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }
`;

const FileNameRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileSelector = styled.div`
  padding: 8px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.radius.medium};
`;

const Container = styled.div`
  width: 475px;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.base.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  maxWidth: 500,
  padding: 12,
  gap: 8,
});
