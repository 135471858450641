import { ConfigCatProvider, useFeatureFlag } from "configcat-react";
import type { PropsWithChildren } from "react";

import { useAuthConfig } from "./AuthConfigProvider";
import { FlagsProvider } from "./FlagsProvider";

export const EnvProvider = ({ children }: PropsWithChildren) => {
  const { env } = useAuthConfig();

  if (env === "dev" || env === "staging") {
    return (
      <FlagsProvider getFlag={() => ({ value: true, loading: false })}>
        {children}
      </FlagsProvider>
    );
  }

  return (
    <ConfigCatProvider
      sdkKey={import.meta.env.VITE_CONFIGCAT_SDK_KEY as string}
    >
      <FlagsProvider getFlag={useFeatureFlag}>{children}</FlagsProvider>
    </ConfigCatProvider>
  );
};
