import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";

import type { InviteDemoFieldValues } from "../InviteDemoPage";

export const InviteDemoClient = {
  inviteUsers: async (data: InviteDemoFieldValues) => {
    const formData = new FormData();
    data.teachers?.[0] && formData.append("teachers", data.teachers[0]);
    await AuthenticatedHttpClient.post(`/demo/invite`, formData);
  },
};
