import { AccordionItem as Item } from "@szhsin/react-accordion";
import { t } from "i18next";
import styled from "styled-components";

import { useTeacherDashboard } from "../../useTeacherDashboard";
import { NoWritingTasks } from "../NoWritingTasks";
import { WritingTaskCard } from "../TeacherWritingTaskCard/WritingTaskCard";
import type { SectionHeaderProps } from "./SectionHeader";
import { SectionHeader } from "./SectionHeader";

export type DashboardSectionProps = {
  opened?: boolean;
  hideActions?: boolean;
} & SectionHeaderProps;

export const DashboardSection = ({
  section,
  opened,
  hideActions,
  ...props
}: DashboardSectionProps) => {
  const { openCreationFor, selectedHomeroomId } = useTeacherDashboard();
  const hasTasks = section.tasks.length > 0;

  return (
    <AccordionItem
      initialEntered={opened}
      buttonProps={{ style: { width: "100%" } }}
      onClick={(e) => {
        e.preventDefault();
      }}
      header={({ state }) => (
        <SectionHeader
          hideActions={hideActions}
          open={state.isEnter}
          {...props}
          section={section}
        />
      )}
    >
      {hasTasks ? (
        <WritingTasks aria-label={t("writingTaskListing")}>
          {section.tasks.map((task) => (
            <WritingTaskCard
              key={task.id}
              writingTask={task}
              homeroomId={selectedHomeroomId}
            />
          ))}
        </WritingTasks>
      ) : (
        <NoWritingTasks
          onCreateClick={() => openCreationFor(selectedHomeroomId)}
        />
      )}
    </AccordionItem>
  );
};

const AccordionItem = styled(Item)`
  margin-top: 32px;
`;

const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 0;
`;
