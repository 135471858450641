import { useTranslation } from "react-i18next";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { useWritingTaskUndoArchive } from "@/application/ui/pages/Dashboard/hooks/useWritingTaskUndoArchive.ts";
import { ReactComponent as EyeIcon } from "@/assets/eye.svg";

import type { ContextMenuAction } from "../MenuAction";

export const Unhide = (writingTask: TeacherWritingTask): ContextMenuAction => {
  const { t } = useTranslation();
  const { undoArchiveWritingTask } = useWritingTaskUndoArchive();

  return {
    label: t("teacherWritingTaskCardAction.archive"),
    children: (
      <>
        <EyeIcon />
        {t("hiddenWritingTask.action.unhide")}
      </>
    ),
    callback: () => undoArchiveWritingTask(writingTask.id),
  };
};
