import { useQueryClient } from "@tanstack/react-query";
import { words } from "lodash";
import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "../../../../lib/button/Button";
import { BaseModal } from "../../../../lib/form/BaseModal";
import { LoadingSpinner } from "../../../../lib/spinner/LoadingSpinner";
import { ToastService } from "../../../../lib/toast/ToastService";
import { theme } from "../../../../theme/theme";
import { WriteAssignmentFormValue } from "../../../WriteAssignment/WriteAssignmentFormValue";
import { DEMO_WORDS_LIMIT } from "../../DemoConstants";
import { TeacherQueryKeys } from "../../utils/TeacherQueryKeys";
import { useSubmitTextAssignment } from "./useSubmitTextAssignment";

export interface SubmitTextAssignmentModalRef {
  display: (taskId: string) => void;
}

export const SubmitTextAssignmentModal = React.forwardRef(
  (_, ref: Ref<SubmitTextAssignmentModalRef>) => {
    const [open, isOpen] = useState<boolean>(false);
    const [writeAssignmentFormValue, setWriteAssignmentFormValue] = useState(
      WriteAssignmentFormValue.init(undefined),
    );
    const [writingTaskId, setWritingTaskId] = useState<string | undefined>(
      undefined,
    );
    const [nbWords, setNbWords] = useState(0);

    const queryClient = useQueryClient();
    const { isLoading, submitTextAssignment } = useSubmitTextAssignment({
      onSuccess: async () => {
        isOpen(false);
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(
          t("submitTextAssignment.success.title"),
          t("submitTextAssignment.success.description"),
        );
      },
      onError: () => {
        ToastService.showError(t("submitTextAssignment.failure"));
      },
    });

    useImperativeHandle(ref, () => ({
      display: (taskId: string) => {
        setWritingTaskId(taskId);
        setWriteAssignmentFormValue(WriteAssignmentFormValue.init(undefined));
        isOpen(true);
      },
    }));

    const textarea = document.getElementById("content");
    const countWords = (text: string) => {
      const count = words(text).length;
      setNbWords(count);
      return count;
    };
    textarea?.addEventListener("keydown", function (e) {
      if ([" ", ".", ",", "!", "?", "Backspace", "Delete"].includes(e.key)) {
        countWords(this.textContent ?? "");
      }
    });

    const handleSubmit = () => {
      const wordsCount = countWords(textarea?.textContent ?? "");
      if (wordsCount > DEMO_WORDS_LIMIT) {
        ToastService.showError(
          t("submitTextAssignment.tooManyWords", {
            max: DEMO_WORDS_LIMIT,
          }),
        );
      } else {
        submitTextAssignment({
          taskId: writingTaskId!,
          text: writeAssignmentFormValue.content,
        });
      }
    };

    const handleClose = () => isOpen(false);

    const { t } = useTranslation();

    return (
      <BaseModal
        open={open}
        onClose={handleClose}
        title={t("submitTextAssignment.title")}
        description={
          <>
            <AssignmentTextArea
              role="textbox"
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              id="content"
              spellCheck={false}
              placeholder={t("writingAssignmentPage.textPlaceholder")}
              value={writeAssignmentFormValue.content}
              onChange={(event) =>
                setWriteAssignmentFormValue(
                  writeAssignmentFormValue.setContent(event.target.value),
                )
              }
            />
            <BottomContainer>
              <WordsCount>{`${nbWords}/${DEMO_WORDS_LIMIT} ${t("words")}`}</WordsCount>
              <Button
                width={120}
                onClick={handleSubmit}
                disabled={nbWords > DEMO_WORDS_LIMIT}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  t("submitTextAssignment.action")
                )}
              </Button>
            </BottomContainer>
          </>
        }
      />
    );
  },
);

const WordsCount = styled.div`
  align-self: center;
  ${theme.typography.sm.medium};
  color: ${theme.colors.gray[600]};
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: auto;
`;

const AssignmentTextArea = styled.textarea`
  height: 50vh;
  padding: 12px 14px;
  border-radius: ${theme.radius.medium};
  border: ${theme.borders.primary};
  background: ${theme.colors.base.white};
  ${theme.typography.md.regular}
  box-shadow: ${theme.shadowAndBlur.xsmall};
  &:focus {
    outline-color: ${theme.colors.primary[300]};
  }
`;
