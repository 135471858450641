import { HttpClient } from "../../client/HttpClient";

export type AuthConfig = {
  env: string;
  tenantId: string;
  applicationId: string;
  entraAuthScope: string;
};

export const EnvConfigClient = {
  authConfig: async () => {
    const { data } = await HttpClient.get<AuthConfig>(`/envConfig/auth`);
    return data;
  },
};
