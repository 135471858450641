import { PositionCalculator } from "@/application/ui/pages/Revision/service/PositionCalculator.ts";

const isRectEncapsulated = (innerRect: DOMRect, outerRect: DOMRect) => {
  return (
    innerRect.x >= outerRect.x &&
    innerRect.y >= outerRect.y &&
    innerRect.x + innerRect.width <= outerRect.x + outerRect.width &&
    innerRect.y + innerRect.height <= outerRect.y + outerRect.height
  );
};

export const getRects = (
  container: Node,
  startOffset: number,
  endOffset: number,
): DOMRect[] => {
  const { startNode, startNodeOffset, endNode, endNodeOffset } =
    PositionCalculator.calculateNodeRange(startOffset, endOffset, container);
  try {
    if (
      !startNode ||
      !endNode ||
      startNodeOffset === undefined ||
      endNodeOffset === undefined
    ) {
      console.warn("Could not locate start and end nodes within the text.");
      return [];
    }

    const range = document.createRange();
    range.setStart(startNode, startNodeOffset);
    range.setEnd(endNode, endNodeOffset);

    const rects = range.getClientRects();
    range.detach();

    const rectList: DOMRect[] = [];
    if (rects.length) {
      for (const rect of rects) {
        rectList.push(rect);
      }
    }

    const filteredRectList: DOMRect[] = [];
    rectList.forEach((rect) => {
      let shouldKeep = true;

      for (const [index, otherRect] of filteredRectList.entries()) {
        if (isRectEncapsulated(rect, otherRect)) {
          shouldKeep = false;
          break;
        } else if (isRectEncapsulated(otherRect, rect)) {
          filteredRectList.splice(index, 1);
        }
      }

      if (shouldKeep) {
        filteredRectList.push(rect);
      }
    });

    return filteredRectList;
  } catch (exception) {
    return [];
  }
};
