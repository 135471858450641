import styled from "styled-components";

import { theme } from "./theme/theme";

export const LayoutContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-height: 100vh;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.grayBlue[50]};
  min-height: 4rem;
  border-bottom: 1px solid ${theme.colors.borders.primary};
`;

export const ContentSpacing = `
  padding-left: 25%;
  padding-right: 25%;

  @media (max-width: 1500px) {
    padding-left: 15%; 
    padding-right: 15%; 
  }

  @media print {
    padding: 0; 
    margin: 0; 
  }
`;
