import styled from "styled-components";

import type { TeacherComment } from "../../../../../domain/Assignment";
import { theme } from "../../../../theme/theme";

type Props = {
  comments: TeacherComment[];
};

export const PdfCommentsSection = ({ comments }: Props) => {
  return (
    <Comments>
      {comments.map((comment, index) => (
        <Comment key={`comment_${comment.id}`}>
          <Index>{`${index + 1}.`}</Index>
          {comment.text}
        </Comment>
      ))}
    </Comments>
  );
};

const Comment = styled.div`
  display: flex;
  gap: 10px;
`;

const Index = styled.div`
  color: ${theme.colors.gray[700]};
  ${theme.typography.md.bold};
  display: flex;
  justify-content: center;
`;

const Comments = styled.div`
  ${theme.typography.md.medium};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 25%;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: ${theme.radius.medium};
  padding: 24px 16px;
`;
