import type { PropsWithChildren } from "react";
import { createContext, useRef, useState } from "react";

import type {
  HomeroomListing,
  WritingTaskListing,
} from "../../../domain/HomeroomListing";
import type { TeacherHomeroomsList } from "../../../domain/Teacher/TeacherDashboard";
import type { DeleteDraftWritingTaskModalRef } from "./components/DeleteDraftWritingTaskModal";
import { DeleteDraftWritingTaskModal } from "./components/DeleteDraftWritingTaskModal";
import {
  DraftWritingTaskUpdatePanel,
  type PanelDraftUpdateRef,
} from "./components/DraftWritingTaskUpdatePanel";
import type { PrintWritingTaskAssignmentsModalRef } from "./components/PrintAssignmentsPdf/PrintWritingTaskAssignmentsModal";
import { PrintWritingTaskAssignmentsModal } from "./components/PrintAssignmentsPdf/PrintWritingTaskAssignmentsModal";
import type { SubmitTextAssignmentModalRef } from "./components/SubmitTextAssignment/SubmitTextAssignmentModal";
import { SubmitTextAssignmentModal } from "./components/SubmitTextAssignment/SubmitTextAssignmentModal";
import {
  type PaneCreationlRef,
  WritingTaskCreationPanel,
} from "./components/WritingTaskCreationPanel";
import {
  WritingTaskSummaryPanel,
  type WritingTaskSummaryPanelRef,
} from "./components/WritingTaskSummaryPanel/WritingTaskSummaryPanel";

export type CorrectionMode = "default" | "unpenalized" | "comment";

export type TeacherDashboardContext = {
  openCreationFor: (homeroomId: string, sectionId?: string) => void;
  openUpdateForDraft: (
    homeroom: HomeroomListing,
    writingTaskId: string,
    sectionId?: string,
  ) => void;
  onOpenGradePanel: (task: WritingTaskListing, homeroomName: string) => void;
  onSectionCreationCompleted: () => void;
  onDeleteDraftWritingTask: (taskId: string) => void;
  onPrintTaskAssignments: (taskId: string) => void;
  onSubmitTextAssignment: (taskId: string) => void;
  setIsCreatingSection: (value: boolean) => void;
  isCreatingSection: boolean;
  includeMasked: boolean;
  selectedHomeroomId: string;
  setIncludeMasked: (value: boolean) => void;
  setHomeroomId: (value: string) => void;
  homerooms: TeacherHomeroomsList;
  homeroomName: string;
};

export const TeacherDashboardContext = createContext<TeacherDashboardContext>({
  openCreationFor: () => {},
  openUpdateForDraft: () => false,
  onOpenGradePanel: () => {},
  onDeleteDraftWritingTask: () => {},
  onPrintTaskAssignments: () => {},
  onSubmitTextAssignment: () => {},
  onSectionCreationCompleted: () => false,
  setIsCreatingSection: () => false,
  setIncludeMasked: () => false,
  isCreatingSection: false,
  includeMasked: false,
  selectedHomeroomId: "",
  setHomeroomId: () => {},
  homerooms: [],
  homeroomName: "",
});

type Props = {
  homerooms: TeacherHomeroomsList;
} & PropsWithChildren;

export const TeacherDashboardProvider = ({ children, homerooms }: Props) => {
  const [selectedHomeroomId, setSelectedHomeroomId] = useState(homerooms[0].id);
  const [homeroomName, setHomeroomName] = useState(
    homerooms.find((x) => x.id === selectedHomeroomId)?.name ??
      homerooms[0].name,
  );
  const [isCreatingSection, setIsCreatingSection] = useState(false);
  const [includeMasked, setIncludeMasked] = useState<boolean>(false);

  const printTaskAssignmentsModalRef =
    useRef<PrintWritingTaskAssignmentsModalRef>(null);
  const submitTextAssignmentModalRef =
    useRef<SubmitTextAssignmentModalRef>(null);
  const taskCreationPanelRef = useRef<PaneCreationlRef>(null);
  const deleteDraftModalRef = useRef<DeleteDraftWritingTaskModalRef>(null);
  const taskUpdateDraftPanelRef = useRef<PanelDraftUpdateRef>(null);
  const gradeSummaryPanelRef = useRef<WritingTaskSummaryPanelRef>(null);

  const openCreationFor = (homeroomId: string, sectionId?: string) =>
    taskCreationPanelRef.current?.openFormForHomeroom(homeroomId, sectionId);

  const openUpdateForDraft = (
    homeroom: HomeroomListing,
    writingTaskId: string,
    sectionId?: string,
  ) =>
    taskUpdateDraftPanelRef.current?.openForDraftUpdate(
      homeroom,
      writingTaskId,
      sectionId,
    );

  const onDeleteDraftWritingTask = (taskId: string) =>
    deleteDraftModalRef.current?.display(taskId);

  const onPrintTaskAssignments = (taskId: string) =>
    printTaskAssignmentsModalRef.current?.display(taskId);

  const onSubmitTextAssignment = (taskId: string) => {
    submitTextAssignmentModalRef.current?.display(taskId);
  };

  const onOpenGradePanel = (task: WritingTaskListing, homeroomName: string) => {
    gradeSummaryPanelRef.current?.openForWritingTask(task);
    gradeSummaryPanelRef.current?.forHomeroom(homeroomName);
  };

  const onSectionCreationCompleted = () => {
    setIsCreatingSection(false);
  };

  const setHomeroomId = (homeroomId: string) => {
    setSelectedHomeroomId(homeroomId);
    setHomeroomName(homerooms.find((x) => x.id === homeroomId)?.name ?? "");
  };

  return (
    <TeacherDashboardContext.Provider
      value={{
        openCreationFor,
        openUpdateForDraft,
        onOpenGradePanel,
        onSectionCreationCompleted,
        setIncludeMasked,
        setIsCreatingSection,
        includeMasked,
        isCreatingSection,
        selectedHomeroomId,
        setHomeroomId,
        homerooms,
        homeroomName,
        onDeleteDraftWritingTask,
        onPrintTaskAssignments,
        onSubmitTextAssignment,
      }}
    >
      {children}
      <WritingTaskCreationPanel
        ref={taskCreationPanelRef}
        homerooms={homerooms}
      />
      <DraftWritingTaskUpdatePanel ref={taskUpdateDraftPanelRef} />
      <WritingTaskSummaryPanel ref={gradeSummaryPanelRef} />
      <DeleteDraftWritingTaskModal ref={deleteDraftModalRef} />
      <PrintWritingTaskAssignmentsModal ref={printTaskAssignmentsModalRef} />
      <SubmitTextAssignmentModal ref={submitTextAssignmentModalRef} />
    </TeacherDashboardContext.Provider>
  );
};
