import type { FrontendConsultationAssignmentResponseDto } from "@api/FrontendAssignmentResponse.dto.ts";
import type { WritingTaskAssignmentsResponseDto } from "@emilia/backend/src/writing-tasks/controller/dto/WritingTaskAssignmentsResponse.dto";

import { AssignmentMapper } from "@/application/client/mapper/AssignmentMapper.ts";
import { GradedAssignmentMapper } from "@/application/client/mapper/GradedAssignmentMapper.ts";
import { WritingTaskMapper } from "@/application/client/mapper/WritingTaskMapper.ts";
import type { ConsultationAssignment } from "@/application/domain/Assignment.ts";
import type { AssignmentEvent } from "@/application/domain/AssignmentEvent.ts";
import type { GradedAssignment } from "@/application/domain/GradedAssignment.ts";
import type { WritingTaskAssignments } from "@/application/domain/WritingTaskAssignments.ts";

import { AuthenticatedHttpClient } from "./HttpClient";

type queueElement = {
  assignmentId: string;
  [k: string]: unknown;
};

export class AssignmentClient {
  static getById = async (
    assignmentId: string,
  ): Promise<ConsultationAssignment> => {
    const { data } =
      await AuthenticatedHttpClient.get<FrontendConsultationAssignmentResponseDto>(
        `/assignments/${assignmentId}`,
      );
    const assignment = AssignmentMapper.fromConsultationDto(data);
    if (assignment && assignment.recordUrl) {
      assignment.recordUrl = await this.getAudioURL(assignmentId);
    }
    return assignment;
  };

  static getAudioURL = async (assignmentId: string): Promise<string> => {
    const { data } = await AuthenticatedHttpClient.get<Blob>(
      `/recording/${assignmentId}`,
      { responseType: "blob" },
    );

    return URL.createObjectURL(data);
  };

  static getFlagged = async (): Promise<queueElement[]> => {
    const { data } = await AuthenticatedHttpClient.get<queueElement[]>(
      "/assignments/all/flagged",
    );
    return data;
  };

  static getForWritingTask = async (
    taskId: string,
  ): Promise<WritingTaskAssignments> => {
    const { data } =
      await AuthenticatedHttpClient.get<WritingTaskAssignmentsResponseDto>(
        `/writing-tasks/${taskId}/assignments`,
      );

    return WritingTaskMapper.assembleWithAssignmentsToDomain(data);
  };

  static getLostAssignment = async (ids: string[]): Promise<queueElement[]> => {
    const { data } = await AuthenticatedHttpClient.get<queueElement[]>(
      "/assignments/all/writing_in_progress_assignment",
      {
        params: { excludedIds: ids },
      },
    );
    return data;
  };

  static sendAssignment = async (
    content: string,
    writingTaskId: string,
    assignmentEvents: AssignmentEvent[],
  ): Promise<void> => {
    const trimedContent = content.trim();
    return AuthenticatedHttpClient.post(`/assignments`, {
      content: trimedContent,
      writingTaskId,
      events: assignmentEvents,
    });
  };

  static saveWIPAssignment = async (
    content: string,
    writingTaskId: string,
    events: AssignmentEvent[],
  ): Promise<void> => {
    const trimedContent = content.trim();
    return AuthenticatedHttpClient.post(`/assignments/save`, {
      content: trimedContent,
      writingTaskId,
      events,
    });
  };

  static gradeAssignment = async (
    gradedAssignment: GradedAssignment,
  ): Promise<void> => {
    const dto = GradedAssignmentMapper.fromGradedAssignment(gradedAssignment);

    await AuthenticatedHttpClient.put(`/assignments/grade`, dto);
  };

  static resendAssignment = async (assignmentId: string): Promise<void> => {
    return AuthenticatedHttpClient.post(
      `/assignments/resubmit/${assignmentId}`,
    );
  };

  static forceSendAssignment = async (assignmentId: string): Promise<void> => {
    return AuthenticatedHttpClient.post(
      `/assignments/force/submit/${assignmentId}`,
    );
  };

  static flagAssignment = async (assignmentId: string): Promise<void> => {
    await AuthenticatedHttpClient.post(`/assignments/flag/${assignmentId}`);
  };

  static submitText = async (
    taskId: string,
    content: string,
  ): Promise<void> => {
    const trimedContent = content.trim();
    return await AuthenticatedHttpClient.post(
      `/assignments/submitedByTeacher/${taskId}`,
      { content: trimedContent },
    );
  };
}
