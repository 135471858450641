import { AssignmentClient } from "@/application/client/AssignmentClient.ts";
import { RecordingClient } from "@/application/client/RecordingClient.ts";
import type { GradedAssignment } from "@/application/domain/GradedAssignment.ts";
import type { AudioRecord } from "@/application/domain/Record/AudioRecord.ts";

interface GradeAssignment {
  gradedAssignment: GradedAssignment;
  recording?: AudioRecord;
}

export class GradeAssignmentService {
  static grade = async ({ gradedAssignment, recording }: GradeAssignment) => {
    await AssignmentClient.gradeAssignment(gradedAssignment);

    if (recording) {
      await RecordingClient.uploadRecord(recording, gradedAssignment.id);
    } else if (!gradedAssignment.recordUrl) {
      await RecordingClient.removeRecord(gradedAssignment.id);
    }
  };
}
