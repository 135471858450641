import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import type {
  AssignmentStatus,
  TeacherComment,
} from "@/application/domain/Assignment.ts";
import { ContentSpacing } from "@/application/ui/assignment.layout.ts";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle";
import { Status } from "@/application/ui/pages/Revision/components/Status.tsx";
import { TextCorrector } from "@/application/ui/pages/Revision/components/TextCorrector/TextCorrector.tsx";
import ToggleView from "@/application/ui/pages/Revision/components/ToggleView/ToggleView.tsx";
import { useToggleView } from "@/application/ui/pages/Revision/components/ToggleView/useToggleView.tsx";
import { useAssignments } from "@/application/ui/pages/Revision/RevisionStates/useAssignments.ts";
import type { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";
import { ReactComponent as MessageIcon } from "@/assets/message.svg";
import { ReactComponent as EraserIcon } from "@/assets/toggleSwitch.svg";
import { ReactComponent as WriteIcon } from "@/assets/write-icon.svg";

import { useLoggedUser } from "../../../authentication/hooks/useLoggedUser";
import { useReadOnlyUser } from "../../context/useReadOnlyUser";
import { theme } from "../../theme/theme";

export interface AssignmentSectionProps {
  currentStudent?: string;
  revisedAssignmentContentState: RevisedAssignmentContentState;
  revisedAssignmentStatus?: AssignmentStatus;
  comments: TeacherComment[];
  onContentStateChange: (
    revisedAssignmentContentState: RevisedAssignmentContentState,
  ) => void;
  onCommentsChange: (comments: TeacherComment[]) => void;
}

export const AssignmentEditor = ({
  currentStudent,
  revisedAssignmentContentState,
  revisedAssignmentStatus,
  comments,
  onContentStateChange,
  onCommentsChange,
}: AssignmentSectionProps) => {
  const { t } = useTranslation();
  const { currentMode, onModeChange } = useAssignments();

  const { currentView, toggleCurrentView } = useToggleView();
  const { readOnlyUser } = useReadOnlyUser();
  const { user } = useLoggedUser();
  const [scrollPositionPercentage, setScrollPositionPercentage] = useState(0);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const getTotalScrollHeight = (container: HTMLDivElement): number => {
    return container.scrollHeight - container.clientHeight;
  };

  const onToggleCurrentView = () => {
    if (textContainerRef.current) {
      setScrollPositionPercentage(
        textContainerRef.current.scrollTop /
          getTotalScrollHeight(textContainerRef.current),
      );
      toggleCurrentView();
    }
  };

  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop =
        scrollPositionPercentage *
        getTotalScrollHeight(textContainerRef.current);
    }
  }, [currentView, scrollPositionPercentage]);

  useEffect(() => {
    setScrollPositionPercentage(0);
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop = 0;
    }
  }, [currentStudent]);

  return (
    <>
      <Header>
        <ToggleView toggleMode={currentView} onToggle={onToggleCurrentView} />

        <Right>
          {revisedAssignmentStatus && (
            <Status status={revisedAssignmentStatus} />
          )}
        </Right>
      </Header>

      {currentView === "original" && (
        <>
          <FloatingBox>
            <>
              <ModeButton
                id="defaultModeButton"
                name="defaultModeButton"
                onClick={() => onModeChange("default")}
                disabled={currentMode === "default"}
                className={clsx({ isSelected: currentMode === "default" })}
              >
                <WriteIcon />
              </ModeButton>
              <Tooltip
                anchorSelect="#defaultModeButton"
                content={t(`correctMode${!user.isTeacher() ? "Student" : ""}`)}
                place="right"
                style={{ ...tooltipStyle }}
              />
            </>
            <>
              <ModeButton
                id="commentModeButton"
                name="commentModeButton"
                onClick={() => onModeChange("comment")}
                disabled={currentMode === "comment"}
                className={clsx({
                  isSelected: currentMode === "comment",
                })}
              >
                <MessageIcon />
              </ModeButton>
              <Tooltip
                anchorSelect="#commentModeButton"
                content={t(`commentMode${!user.isTeacher() ? "Student" : ""}`)}
                place="right"
                style={{ ...tooltipStyle }}
              />
            </>
            {user.isTeacher() && (
              <>
                <ModeButton
                  id="unpenalizedModeButton"
                  name="unpenalizedModeButton"
                  onClick={() => onModeChange("unpenalized")}
                  disabled={currentMode === "unpenalized"}
                  className={clsx({
                    isSelected: currentMode === "unpenalized",
                  })}
                >
                  <EraserIcon />
                </ModeButton>
                <Tooltip
                  anchorSelect="#unpenalizedModeButton"
                  content={t("unpenalizedMode")}
                  place="right"
                  style={{ ...tooltipStyle }}
                />
              </>
            )}
          </FloatingBox>
          <TextContainer ref={textContainerRef}>
            <TextCorrector
              data-testid="text-corrector"
              revisedAssignmentContentState={revisedAssignmentContentState}
              comments={comments}
              onContentStateChange={onContentStateChange}
              onCommentsChange={onCommentsChange}
              readOnly={readOnlyUser}
            />
          </TextContainer>
        </>
      )}

      {currentView === "revised" && (
        <TextContainer ref={textContainerRef} data-testid="revised-version">
          {revisedAssignmentContentState.toRevisedString()}
        </TextContainer>
      )}
    </>
  );
};

const TextContainer = styled.div`
  line-height: ${theme.typography.lineHeight.textCorrector};
  overflow-x: visible;
  overflow-y: auto;
  margin-top: 10px;
  padding-top: 24px;
  padding-bottom: 48px;
  white-space: pre-line;

  ${ContentSpacing};

  ${scrollbarStyle()}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  box-sizing: border-box;

  ${ContentSpacing};
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: fit-content;
`;

const FloatingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;
  gap: 12px;
  border-radius: ${theme.radius.round};

  border: ${theme.borders.primary};
  background: ${theme.colors.grayBlue[50]};
  box-shadow: ${theme.shadowAndBlur.large};

  position: absolute;
  top: 40%;
  left: 85px;
`;

const ModeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: ${theme.radius.round};
  aspect-ratio: 1;

  &.isSelected {
    background-color: ${theme.colors.primary[100]};
  }

  &:hover {
    background-color: ${theme.colors.gray[200]};
  }

  &:disabled {
    cursor: default;

    &:hover {
      background-color: ${theme.colors.primary[100]};
    }
  }
`;
