import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { ReactComponent as DotsVertical } from "@/assets/dots-vertical.svg";

import type { Button } from "../../../../../../lib/button/Button.tsx";
import { theme } from "../../../../../../theme/theme.ts";
import type { ContextMenuAction } from "./MenuAction.tsx";
import { MenuAction } from "./MenuAction.tsx";
import { ContextualMenu } from "./WritingTaskContextMenu.tsx";

export type WritingTaskContextMenuActionsProps = {
  actions: ContextMenuAction[];
  demoActions?: ContextMenuAction[];
};

export const WritingTaskContextMenuActions = ({
  actions,
  demoActions,
}: WritingTaskContextMenuActionsProps) => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openContextMenu: React.ComponentProps<typeof Button>["onClick"] = (
    e,
  ) => {
    e.preventDefault();
    setMenuOpen(true);
  };
  const closeContextMenu = () => setMenuOpen(false);

  return (
    <Popover
      isOpen={isMenuOpen}
      positions={["right", "left"]}
      align="start"
      onClickOutside={closeContextMenu}
      content={
        <ContextualMenu>
          <ActionsGroup>
            {demoActions && (
              <DemoLabel>
                {t("teacherWritingTaskCardAction.demoOptions")}
              </DemoLabel>
            )}
            {demoActions?.map((action) => (
              <MenuAction
                key={`menuAction-${action.label}`}
                action={action}
                closeContextMenu={closeContextMenu}
              />
            ))}
          </ActionsGroup>
          {actions.map((action) => (
            <MenuAction
              key={`menuAction-${action.label}`}
              action={action}
              closeContextMenu={closeContextMenu}
            />
          ))}
        </ContextualMenu>
      }
    >
      <IconButton
        onClick={openContextMenu}
        aria-label={t("moreOptions")}
        hierarchy="neutral"
        size="xs"
        variant="text"
      >
        <DotsVertical />
      </IconButton>
    </Popover>
  );
};

const DemoLabel = styled.div`
  color: ${theme.colors.gray[600]};
  ${theme.typography.xs.medium};
  padding: 8px 16px;
`;

const ActionsGroup = styled.div`
  border-bottom: 1px solid ${theme.colors.gray[200]};
`;
