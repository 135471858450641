import type { RouteObject } from "react-router-dom";

import { AdminDashboard } from "@/application/ui/pages/Dashboard/AdminDashboard.tsx";
import { StudentDashboard } from "@/application/ui/pages/Dashboard/StudentDashboard.tsx";
import { GradeProvider } from "@/application/ui/pages/GradeReport/GradeStates/GradeProvider.tsx";
import { ReportDashboard } from "@/application/ui/pages/GradeReport/ReportDashboard.tsx";
import { ImportUsersPage } from "@/application/ui/pages/ImportUsers/ImportUsersPage.tsx";
import { LoginPage } from "@/application/ui/pages/Login/LoginPage.tsx";
import { RevisionPage } from "@/application/ui/pages/Revision/RevisionPage.tsx";
import { RevisionRequestPage } from "@/application/ui/pages/RevisionRequest/RevisionRequestPage.tsx";
import { StudentAssignmentPage } from "@/application/ui/pages/StudentAssignment/StudentAssignmentPage.tsx";
import { WriteAssignmentPage } from "@/application/ui/pages/WriteAssignment/WriteAssignmentPage.tsx";
import { RedirectOnLogin } from "@/application/ui/routing/RedirectOnLogin.tsx";

import { HomeroomRole } from "../../authentication/domain/LoggedUser.ts";
import { RequireAuth } from "../../authentication/RequireAuth.tsx";
import { RequireRole } from "../../authentication/RequireRole.tsx";
import { MainLayout } from "../layout/MainLayout.tsx";
import { PdfPreviewPage } from "../pages/Dashboard/components/PrintAssignmentsPdf/PdfPreviewPage.tsx";
import { DashboardPage } from "../pages/Dashboard/DashboardPage.tsx";
import { TeacherDashboard } from "../pages/Dashboard/TeacherDashboard.tsx";
import { WritingTaskGradeReport } from "../pages/GradeReport/WritingTaskGradeReport.tsx";
import { InviteDemoPage } from "../pages/InviteDemo/InviteDemoPage.tsx";

export const routesConfig: RouteObject[] = [
  {
    path: "/connexion",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: <RedirectOnLogin />,
      },
      {
        path: "professeur/pdf/:writingTaskId",
        element: (
          <RequireRole role={HomeroomRole.Teacher}>
            <PdfPreviewPage />
          </RequireRole>
        ),
      },
      {
        path: "professeur",
        element: (
          <RequireRole role={HomeroomRole.Teacher}>
            <MainLayout />
          </RequireRole>
        ),
        children: [
          {
            path: "",
            element: <TeacherDashboard />,
          },
          {
            path: "revision/:writingTaskId/:studentId?",
            element: <RevisionPage />,
          },
          {
            path: "composition/:writingTaskId",
            element: <WriteAssignmentPage />,
          },
          {
            path: "bilan",
            children: [
              { path: "", element: <ReportDashboard /> },
              {
                path: ":homeroomId",
                children: [
                  {
                    path: "",
                    element: <DashboardPage />,
                  },
                  {
                    path: ":writingTaskId",
                    element: (
                      <GradeProvider>
                        <WritingTaskGradeReport />
                      </GradeProvider>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "etudiant",
        element: (
          <RequireRole role={HomeroomRole.Student}>
            <MainLayout />
          </RequireRole>
        ),
        children: [
          {
            path: "",
            element: <StudentDashboard />,
          },
          {
            path: "travail/:assignmentId",
            element: <StudentAssignmentPage />,
          },
          {
            path: "composition/:writingTaskId",
            element: <WriteAssignmentPage />,
          },
        ],
      },
      {
        path: "administrateur",
        element: (
          <RequireRole role={HomeroomRole.Admin}>
            <MainLayout />
          </RequireRole>
        ),
        children: [
          { path: "", element: <AdminDashboard /> },
          { path: "revision-request/", element: <RevisionRequestPage /> },
          { path: "import-users/", element: <ImportUsersPage /> },
          { path: "invite-demo/", element: <InviteDemoPage /> },
        ],
      },
    ],
  },
];
