import type { ChangeEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { IconButton } from "@/application/ui/lib/button/IconButton";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";
import { ReactComponent as SaveIcon } from "@/assets/arrow-right-circle.svg";
import { ReactComponent as EditIcon } from "@/assets/edit.svg";
import { ReactComponent as CancelIcon } from "@/assets/x-close.svg";

import { theme } from "../../../../theme/theme";

type Props = {
  readOnly: boolean;
  comment: TeacherComment;
  onDeleteComment: () => void;
  onSave: (commentText: string) => void;
  onClose: () => void;
};

export const CommentPopupViewMode = ({
  readOnly,
  comment,
  onDeleteComment,
  onSave,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(
    comment.text.trim() === "" && !readOnly,
  );
  const [commentText, setCommentText] = useState(comment.text || "");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (commentText.toString() === "" && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  });

  return (
    <>
      <Section>
        <Header>
          {isEditing ? t("addComment") : t("commentDetails")}

          <CloseButton
            variant="text"
            hierarchy="neutral"
            onClick={onClose}
            title={t("closePopup")}
            style={{ padding: 2 }}
          >
            <CancelIcon />
          </CloseButton>
        </Header>
      </Section>

      <CommentContainer>
        {isEditing ? (
          <>
            <Input
              aria-label="revised-text-input"
              value={commentText}
              placeholder={t("writeCommentHere")}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setCommentText(event.target.value)
              }
              ref={textAreaRef}
              style={{ width: "100%" }}
              autoFocus
            />
            <ConfirmCommentButton
              onClick={() => {
                onSave(commentText);
                setIsEditing(false);
              }}
            >
              <StyledSaveIcon
                data-testid="saveComment"
                width={20}
                height={20}
              />
            </ConfirmCommentButton>
          </>
        ) : (
          <Comment>{commentText}</Comment>
        )}
      </CommentContainer>

      {!readOnly && !isEditing && (
        <ButtonContainer>
          <Button hierarchy="error" variant="text" onClick={onDeleteComment}>
            {t("delete")}
          </Button>

          <Button
            hierarchy="neutral"
            variant="outlined"
            onClick={() => setIsEditing(!isEditing)}
          >
            <ButtonContent>
              <StyledEditIcon
                height={20}
                style={{ padding: 0, paddingRight: "0.5rem" }}
              />
              <p>{t("modify")}</p>
            </ButtonContent>
          </Button>
        </ButtonContainer>
      )}

      {readOnly && (
        <ButtonContainer>
          <Button hierarchy="neutral" variant="outlined" onClick={onClose}>
            <ButtonContent>
              <p>{t("close")}</p>
            </ButtonContent>
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

const Input = styled.textarea`
  ${theme.typography.sm.regular}
  height: 6rem;
  border: ${theme.borders.primary};
  border-radius: ${theme.radius.medium};
  padding: 12px;
  resize: none;

  ${scrollbarStyle()};

  &:focus {
    outline-color: ${theme.colors.primary[300]};

    &::placeholder {
      color: transparent;
    }
  }
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.typography.xs.bold};
`;

const StyledEditIcon = styled(EditIcon)`
  border-radius: ${theme.radius.medium};
  padding: 10px;
  cursor: pointer;
`;

const StyledSaveIcon = styled(SaveIcon)`
  color: ${theme.colors.success[500]};
  cursor: pointer;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.gray[500]};
  padding: 8px 16px;
`;

const CommentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
`;

const ConfirmCommentButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 28px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px 16px 16px;
  gap: 16px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Comment = styled.p`
  flex: 1;
  border-radius: ${theme.radius.small};
  padding: 14px;
  word-wrap: anywhere;
`;

const CloseButton = styled(IconButton)`
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
