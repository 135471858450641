import { useQuery } from "@tanstack/react-query";

import { AssignmentClient } from "@/application/client/AssignmentClient.ts";

type UseAudioUrl = {
  audioUrl: string | undefined;
  isLoading: boolean;
  error: unknown;
};

export const useAudioUrl = (id: string): UseAudioUrl => {
  const { data, isLoading, error } = useQuery(["assignment-by-id", id], () =>
    AssignmentClient.getAudioURL(id),
  );
  return {
    audioUrl: data,
    isLoading,
    error,
  };
};
