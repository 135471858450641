import type { AudioRecord } from "@/application/domain/Record/AudioRecord.ts";

import { HttpClient } from "./HttpClient";

export class RecordingClient {
  static async uploadRecord(record: AudioRecord, gradedAssignmentId: string) {
    await HttpClient.post(
      `/assignments/${gradedAssignmentId}/recording`,
      this.assembleFrom(record),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }
  static async removeRecord(gradedAssignmentId: string) {
    await HttpClient.delete(`/assignments/${gradedAssignmentId}/recording`);
  }

  private static assembleFrom(record: AudioRecord) {
    const formData = new FormData();
    formData.append("blob", record.blob);
    formData.append("extension", record.EXTENSION);

    return formData;
  }
}
