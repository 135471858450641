import { Accordion } from "@szhsin/react-accordion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Page } from "@/application/ui/lib/Page";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage";
import { theme } from "@/application/ui/theme/theme";

import { useCreateSection } from "../hooks/useCreateSection";
import { useTeacherHomeroomDashboard } from "../hooks/useTeacherHomeroomDashboard";
import { useTeacherDashboard } from "../useTeacherDashboard";
import { DashboardSection } from "./Section/DashboardSection";
import { SectionHeaderEdition } from "./Section/SectionHeaderEdition";

export const TeacherDashboardSections = () => {
  const { t } = useTranslation();
  const {
    selectedHomeroomId,
    includeMasked,
    onSectionCreationCompleted,
    isCreatingSection,
  } = useTeacherDashboard();

  const { dashboard, isError: isErrorDashboard } = useTeacherHomeroomDashboard(
    selectedHomeroomId,
    includeMasked,
  );

  const { createSection: createSectionMutation } = useCreateSection();

  const onCreateSection = (name: string) => {
    void createSectionMutation({ name }).then(onSectionCreationCompleted);
  };

  if (isErrorDashboard) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!dashboard) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  return (
    <Group aria-labelledby={dashboard.id}>
      {isCreatingSection && (
        <SectionHeaderEdition
          onCancel={onSectionCreationCompleted}
          onSubmit={onCreateSection}
        />
      )}
      <Accordion allowMultiple>
        {dashboard.sections.map((section, index) => (
          <DashboardSection
            opened={index === 0}
            section={section}
            key={section.id}
          />
        ))}
        <DashboardSection
          opened={dashboard.sections.length === 0}
          hideActions
          section={{
            id: "0",
            name: t("sections.unclassified"),
            tasks: dashboard.sectionlessTasks,
          }}
        />
      </Accordion>
    </Group>
  );
};

const Group = styled.section`
  &.separator {
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`;
