import styled from "styled-components";

import { theme } from "../../../../theme/theme";

type Props = {
  studentName: string;
  taskName: string;
  groupName: string;
};

export const PdfHeader = ({ studentName, taskName, groupName }: Props) => {
  return (
    <Header>
      <div>{studentName}</div>
      <div>{`${taskName} - ${groupName}`}</div>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  border-bottom: 0.5px solid ${theme.colors.gray[300]};
  color: ${theme.colors.gray[700]};
  ${theme.typography.sm.regular};
  background-color: ${theme.colors.gray[25]};
`;
