import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as TrashIcon } from "@/assets/trash.svg";

import { theme } from "../../../../../../../theme/theme";
import type { ContextMenuAction } from "../MenuAction";

export const Delete = (onDeleteDraft: () => void): ContextMenuAction => {
  const { t } = useTranslation();
  return {
    label: t("draftWritingTask.menuActions.delete"),
    children: (
      <>
        <DeleteIcon />
        <DeleteContainer>
          {t("draftWritingTask.menuActions.delete")}
        </DeleteContainer>
      </>
    ),
    callback: onDeleteDraft,
  };
};

const DeleteIcon = styled(TrashIcon)`
  * {
    stroke: ${theme.colors.error[500]};
  }
`;

const DeleteContainer = styled.div`
  color: ${theme.colors.error[500]};
`;
