import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { WritingTaskAssignmentStatuses } from "@/application/domain/WritingTask/WritingTaskAssignmentStatuses.ts";

import { tooltipStyle } from "../../../../../lib/tooltip/tooltipStyle.ts";
import { theme } from "../../../../../theme/theme.ts";

export interface ProgressBarStatuses {
  key: WritingTaskAssignmentStatuses;
  color: string;
  bgcolor: string;
}

export interface StatusesValues {
  key: WritingTaskAssignmentStatuses;
  value: number;
}

const statusesSettings: ProgressBarStatuses[] = [
  {
    key: WritingTaskAssignmentStatuses.NOT_SUBMITTED,
    bgcolor: theme.colors.gray[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.REDACTING,
    bgcolor: theme.colors.warning[500],
    color: theme.colors.base.black,
  },
  {
    key: WritingTaskAssignmentStatuses.SUBMITTED,
    bgcolor: theme.colors.warning[300],
    color: theme.colors.base.black,
  },
  {
    key: WritingTaskAssignmentStatuses.AI_ERROR,
    bgcolor: theme.colors.error[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.CORRECTED,
    bgcolor: theme.colors.indigo[500],
    color: theme.colors.base.white,
  },
  {
    key: WritingTaskAssignmentStatuses.VALID,
    bgcolor: theme.colors.success[500],
    color: theme.colors.base.white,
  },
];

interface ProgressBarProps {
  statuses: StatusesValues[];
  taskId: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BarLayout = styled.div`
  display: flex;
  height: 24px;
  margin-bottom: 10px;
`;

const BaseProgressBar = styled.div`
  display: flex;
  flex-grow: 1;
  height: 24px;
  > *:first-child:only-child {
    border-radius: ${theme.radius.xsmall};
  }
  > *:first-child:not(:only-child) {
    border-radius: ${theme.radius.xsmall} 0 0 ${theme.radius.xsmall};
  }
  > *:last-child:not(:only-child) {
    border-radius: 0 ${theme.radius.xsmall} ${theme.radius.xsmall} 0;
  }
`;

const StatusPartialBar = styled.div<{ $bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgcolor};
  ${theme.typography.xs.regular}
`;

const StatusValue = styled.div<{ $color: string }>`
  color: ${(props) => props.$color};
  ${theme.typography.xs.regular}
`;

const ProgressTotal = styled.div`
  ${theme.typography.xs.regular}
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const WritingTaskProgressBar = (props: ProgressBarProps) => {
  return (
    <Container>
      <BarLayout>
        <StatusesBar {...props} />
      </BarLayout>
    </Container>
  );
};

const StatusesBar = ({ statuses, taskId }: ProgressBarProps) => {
  const { t } = useTranslation();
  const total = statuses.reduce((acc, status) => acc + status.value, 0);

  const statusesToDisplay = statuses.filter((x) => x.value > 0);
  return (
    <>
      <BaseProgressBar role="grid">
        {statusesToDisplay.map((status, index) => {
          const id = `${status.key}${taskId}`;
          return (
            <>
              <StatusPartialBar
                id={id}
                role="gridcell"
                aria-label={`${t(status.key)} : ${status.value}`}
                $bgcolor={
                  statusesSettings.find((x) => x.key === status.key)!.bgcolor
                }
                key={index}
                style={{ width: `${(status.value / total) * 100}%` }}
              >
                <StatusValue
                  $color={
                    statusesSettings.find((x) => x.key === status.key)!.color
                  }
                >
                  {status.value}
                </StatusValue>
              </StatusPartialBar>
              <Tooltip
                anchorSelect={`#${id}`}
                content={t(status.key)}
                place="bottom"
                border={`1px solid ${theme.colors.gray[200]}`}
                style={{
                  ...tooltipStyle,
                  boxShadow: theme.shadowAndBlur.small,
                  borderRadius: 12,
                  color: theme.colors.gray[800],
                }}
              />
            </>
          );
        })}
      </BaseProgressBar>
      <ProgressTotal>{total}</ProgressTotal>
    </>
  );
};
