import { useContext } from "react";

import { DemoTeacherDashboardContext } from "./DemoTeacherDashboardProvider";

export const useDemoTeacherDashboard = (): DemoTeacherDashboardContext => {
  const context = useContext(DemoTeacherDashboardContext);

  if (!context) {
    throw new Error(
      "DemoTeacherDashboardProvider must be instanciated before use",
    );
  }

  return context;
};
