import { theme } from "@/application/ui/theme/theme.ts";

export const scrollbarStyle = () => `
  /* Firefox */
  scrollbar-color: ${theme.colors.gray[400]}
    ${theme.colors.gray[100]};
  scrollbar-width: thin;

  /* others */
  &::-webkit-scrollbar {
    background: ${theme.colors.gray[100]};
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.gray[400]};
    border-radius:  ${theme.radius.small};
  }`;
