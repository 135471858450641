import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as MaintenanceIcon } from "@/assets/maintenance.svg";

import { theme } from "../../theme/theme";
import { useFlags } from "../App/FlagsProvider";

export const MaintenanceBanner = () => {
  const { t } = useTranslation();
  const { maintenance } = useFlags();

  if (window.location.pathname.includes("etudiant")) {
    return;
  }

  return (
    maintenance && (
      <Banner>
        <MaintenanceIcon />
        {t("maintenance")}
      </Banner>
    )
  );
};

const Banner = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  ${theme.typography.xs.regular};
  background-color: ${theme.colors.warning[100]};
  color: ${theme.colors.gray[900]};
  width: 100%;
  height: 50px;
`;
