import { t } from "i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as PrinterIcon } from "@/assets/printer.svg";

import { Button } from "../../../../lib/button/Button";
import { LoadingSpinner } from "../../../../lib/spinner/LoadingSpinner";
import { scrollbarStyle } from "../../../../theme/Scrollbar";
import { theme } from "../../../../theme/theme";
import { AssignmentsProvider } from "../../../Revision/RevisionStates/AssignmentsProvider";
import { useWritingTaskWithAssignments } from "../../../Revision/useWritingTaskWithAssignments";
import { AssignmentsPdf } from "./AssignmentsPdf";

export const PdfPreviewPage = () => {
  const { writingTaskId } = useParams();
  const { isLoading, writingTaskWithAssignments } =
    useWritingTaskWithAssignments(writingTaskId ?? "");

  if (isLoading) {
    return <LoadingSpinner />;
  }

  document.title = `${writingTaskWithAssignments?.writingTask.name}-${writingTaskWithAssignments?.homeroom.name}`;

  return (
    <PrintablePage>
      <Layout>
        <AssignmentsProvider>
          <PdfWrapper className="printable">
            <AssignmentsPdf taskWithAssignments={writingTaskWithAssignments!} />
          </PdfWrapper>
        </AssignmentsProvider>
        <FloatingButton
          width={150}
          hierarchy="neutral"
          className="unprintable"
          type="button"
          size="xl"
          onClick={() => window.print()}
        >
          <PrinterIcon width={40} />
          {t("printTaskAssignments.action")}
        </FloatingButton>
      </Layout>
    </PrintablePage>
  );
};

const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const PrintablePage = styled.div`
  ${scrollbarStyle()}
  background-color: ${theme.colors.gray[100]};
  ${theme.typography.lg.medium};
  padding: 64px;

  @media print {
    padding: 0px;

    .unprintable,
    .unprintable * {
      display: none;
    }

    .printable {
      visibility: visible;
      padding: 0;
      margin: 0;
      gap: 0;
    }
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  overflow: visible;
  gap: 30px;
`;

const FloatingButton = styled(Button)`
  position: sticky; !important; 
  top: 20px; 
  align-self: flex-start;
`;
