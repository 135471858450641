import type { FormEvent, Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import type { HomeroomListing } from "@/application/domain/HomeroomListing.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { RightPanel } from "@/application/ui/lib/RightPanel.tsx";
import { LoadingSpinner } from "@/application/ui/lib/spinner/LoadingSpinner.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { WritingTaskContent } from "@/application/ui/pages/Dashboard/components/WritingTaskPanel/WritingTaskContent.tsx";
import { useWritingTaskCreation } from "@/application/ui/pages/Dashboard/hooks/useWritingTaskCreation.ts";

import { useSections } from "../hooks/useSections";

export interface PaneCreationlRef {
  openFormForHomeroom: (homeroomId: string, sectionId?: string) => void;
}

interface PanelCreationProps {
  homerooms: HomeroomListing[];
}

export const WritingTaskCreationPanel = React.forwardRef(
  ({ homerooms }: PanelCreationProps, ref: Ref<PaneCreationlRef>) => {
    const { t } = useTranslation();
    const [currentHomeroomId, setHomeroomId] = useState<string | undefined>(
      undefined,
    );
    const [sectionId, setSectionId] = useState<string | undefined>(undefined);
    const { createWritingTask, isLoading } = useWritingTaskCreation();
    const { sections } = useSections();

    useImperativeHandle(ref, () => ({
      openFormForHomeroom: (homeroomId, sectionId) => {
        setHomeroomId(homeroomId);
        setSectionId(sectionId);
      },
    }));

    const submit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);

      const criterias = Object.fromEntries(formData) as {
        C1: string;
        C2: string;
        C3: string;
        C4: string;
        C5: string;
      };
      if (
        !(
          criterias.C1 ||
          criterias.C2 ||
          criterias.C3 ||
          criterias.C4 ||
          criterias.C5
        )
      ) {
        ToastService.showError(t("atLeastOneCriteria"));
        return;
      }

      const homeroomIds = formData.getAll("groupIds[]") as string[];

      if (homeroomIds.length === 0) {
        ToastService.showError(t("failToCreateWritingTaskGroupNotSelected"));
        return;
      }

      createWritingTask({ homeroomIds, formData })
        .then(() => {
          setHomeroomId(undefined);
          setSectionId(undefined);
          ToastService.showSuccess(
            t("writingTaskCreatedSuccessfully", {
              title: formData.get("title"),
            }),
          );
        })
        .catch(() => ToastService.showError(t("failToCreateWritingTask")));
    };

    const currentHomeroom = homerooms.find((x) => x.id === currentHomeroomId);
    return (
      <form onSubmit={submit}>
        <RightPanel opened={!!currentHomeroom}>
          <RightPanel.Header>
            <RightPanel.Title>{t("writingTaskCreation")}</RightPanel.Title>
            {!!currentHomeroom && currentHomeroom.name && (
              <RightPanel.Subtitle>{`(${currentHomeroom.name})`}</RightPanel.Subtitle>
            )}
          </RightPanel.Header>

          <RightPanel.Content>
            <WritingTaskContent
              defaultSectionId={sections[0]?.id}
              sections={sections?.map((x) => ({
                ...x,
                selected: x.id === sectionId,
              }))}
              homerooms={homerooms.map((homeroom) => {
                return {
                  id: homeroom.id,
                  name: homeroom.name,
                  selected: homeroom.id === currentHomeroom?.id,
                };
              })}
            />
          </RightPanel.Content>

          <RightPanel.Footer>
            <Button disabled={isLoading} size="md" type="submit">
              {isLoading ? <LoadingSpinner /> : t("create")}
            </Button>
            <Button
              variant="text"
              hierarchy="neutral"
              size="md"
              disabled={isLoading}
              onClick={() => {
                setHomeroomId(undefined);
                setSectionId(undefined);
              }}
            >
              {t("cancel")}
            </Button>
          </RightPanel.Footer>
        </RightPanel>
      </form>
    );
  },
);
