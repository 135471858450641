import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme.ts";

const Container = styled.div<{ wide?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  ${({ wide }) => wide && "width: 100%"};
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    border-radius: ${theme.radius.medium};
    cursor: pointer;

    border: ${theme.borders.primary};
    background: ${theme.colors.base.white};
  
    box-shadow: ${theme.shadowAndBlur.xsmall};

    &.edges-straight {
      border-radius: 0;
    }
      
    &.edges-round {
      border-radius: 100px
    }

    &.focused {
      border-color: ${theme.colors.primary[300]};
    }
    
    &.disabled {
      cursor: default;
      border-color: ${theme.colors.gray[300]};
      background-color: ${theme.colors.gray[50]};
    }

    &.size-sm {
      padding: 8px 12px;
    }
    
    &.size-md {
      padding: 10px; 16px;
    }
`;

const InputStyle = () => `
    border: none;
    width: 100%;
    ${theme.typography.sm.regular}

    outline: none;
    box-shadow: none;   
    
    &:focus, &:active {
      outline: none;
      box-shadow: none;
    } 

    &:disabled {
      color: ${theme.colors.gray[500]};
    }
`;

const Label = styled.label`
  ${theme.typography.sm.regular}

  &.required::after {
    content: "*";
    color: ${theme.colors.orangeDark[600]};
    margin-left: 2px;
  }
`;

export const BaseInput = {
  Container,
  Label,
  InputBox,
  InputStyle,
};
