import styled from "styled-components";

import { ClosedHeader } from "./ClosedHeader";
import {
  ContextMenuFactory,
  type ContextMenuProps,
} from "./ContextMenu/ContextMenuFactory";
import { DraftHeader } from "./DraftHeader";
import { MaskedHeader } from "./MaskedHeader";

export const HeaderFactory = (props: ContextMenuProps) => {
  switch (props.writingTask.typeStatus) {
    case "draft":
      return (
        <MenuRow>
          <DraftHeader />
          <ContextMenuFactory {...props} />
        </MenuRow>
      );
    case "closed":
      return (
        <MenuRow>
          <ClosedHeader />
          <ContextMenuFactory {...props} />
        </MenuRow>
      );
    case "masked":
      return (
        <MenuRow>
          <MaskedHeader />
          <ContextMenuFactory {...props} />
        </MenuRow>
      );
    case "open":
      return (
        <MenuRow>
          <div></div>
          <ContextMenuFactory {...props} />
        </MenuRow>
      );
    default:
      break;
  }
};

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;
