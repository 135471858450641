import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { GradingScale } from "@/application/ui/pages/Revision/components/GradingScale/GradingScale.tsx";
import { CommentTextArea } from "@/application/ui/pages/Revision/components/Sidebar/CommentTextArea/CommentTextArea.tsx";
import { ErrorSummary } from "@/application/ui/pages/Revision/components/Sidebar/ErrorSummary/ErrorSummary.tsx";
import { GlobalGradeSection } from "@/application/ui/pages/Revision/components/Sidebar/GlobalGradeSection.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";
import { TeacherRecorder } from "@/application/ui/pages/Revision/components/Sidebar/TeacherRecorder.tsx";
import type { RevisableAndGradableAssignment } from "@/application/ui/pages/Revision/service/RevisableAndGradableAssignment.ts";
import { useSaveGradedAssignment } from "@/application/ui/pages/WriteAssignment/useSaveGradedAssignment.ts";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";

import { theme } from "../../../../theme/theme";

type SidebarProps = {
  revisableAssignment: RevisableAndGradableAssignment;
  evaluatedCriteria: EvaluationCriteria;
  onChangeRevisableAssignment: (
    revisableAssignment: RevisableAndGradableAssignment,
  ) => void;
  onUpdateSuccess: () => void;
  hasChanged: boolean;
};

export const AssignmentSidebar = ({
  revisableAssignment,
  evaluatedCriteria,
  onChangeRevisableAssignment,
  onUpdateSuccess,
  hasChanged,
}: SidebarProps) => {
  const { t } = useTranslation();

  const clearComment = () => {
    onChangeRevisableAssignment(revisableAssignment.updateComment(""));
  };

  const { saveGradedAssignment, assignmentIsSaving } = useSaveGradedAssignment({
    onSuccess: () => {
      onUpdateSuccess();
      ToastService.showSuccess(t("confirmGradeSuccess"));
    },
    onError: () => ToastService.showError(t("confirmGradeFail")),
  });
  const confirmGrade = () => {
    const teacherGradedAssignment =
      revisableAssignment.toGradedAssignment(evaluatedCriteria);
    saveGradedAssignment({
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      gradedAssignment: teacherGradedAssignment!,
      recording: revisableAssignment.audioRecord,
    });
  };

  return (
    <Container>
      <AssignmentSummaryContainer>
        <ErrorSummary
          revisedAssignmentContentState={
            revisableAssignment.revisedAssignmentContentState
          }
          commentsCount={revisableAssignment.assignment.comments.length}
        />

        <GradingScale
          revisableAssignment={revisableAssignment}
          evaluatedCriteria={evaluatedCriteria}
          onChangeEditableCriterion={(criterion, grade) => {
            onChangeRevisableAssignment?.(
              revisableAssignment.updateCriterionManually(criterion, grade),
            );
          }}
        />

        <SidebarBox title={t("audioComment")}>
          <TeacherRecorder
            onDelete={() => {
              onChangeRevisableAssignment(revisableAssignment.removeRecord());
            }}
            onAudioCompleted={(audio) =>
              onChangeRevisableAssignment(
                revisableAssignment.updateAudioRecord(audio),
              )
            }
            url={revisableAssignment.audioUrl}
            assignmentId={
              revisableAssignment.assignment.recordUrl
                ? revisableAssignment.assignment.id
                : undefined
            }
          />
        </SidebarBox>

        <CommentTextArea
          key={revisableAssignment.assignment?.id}
          comment={revisableAssignment.comment}
          onClearComment={clearComment}
          onChange={(newValue) =>
            onChangeRevisableAssignment(
              revisableAssignment.updateComment(newValue),
            )
          }
        />
      </AssignmentSummaryContainer>

      <GlobalGradeSection
        className="global-grade-section"
        evaluatedCriteria={evaluatedCriteria}
        onConfirmGrade={confirmGrade}
        assignmentIsSaving={assignmentIsSaving}
        revisableAssignment={revisableAssignment}
        hasChanged={hasChanged}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${theme.colors.gray[50]};
  border-left: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadowAndBlur.xxlarge};
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  height: 100%;
  position: sticky;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .global-grade-section {
    background-color: ${theme.colors.gray[50]};
    box-shadow: 0px -4px 20px 0px rgba(189, 189, 189, 0.15);
    padding: 1rem;
    box-sizing: border-box;
  }
`;

const AssignmentSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 24px 24px 16px;

  ${scrollbarStyle()}
`;
