import "react-loading-skeleton/dist/skeleton.css";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGrades } from "@/application/ui/pages/GradeReport/GradeStates/useGrades.ts";

import { Button } from "../../lib/button/Button";
import { ANIMATION_DURATION } from "../../lib/RightPanel";
import { AssignmentConsultationPanel } from "./AssignmentConsultationPanel";

export const GradeReportByStudent = () => {
  const { t } = useTranslation();
  const { gradeContextDto, updateContext, student } = useGrades();

  return (
    <>
      <AnchoredButton
        size="md"
        onClick={() => {
          updateContext({
            panelOpened: !gradeContextDto.isPanelOpened,
          });
        }}
        offset={gradeContextDto.isPanelOpened ? 850 : 0}
        className={gradeContextDto.isPanelOpened ? "opened" : ""}
      >
        {gradeContextDto.isPanelOpened
          ? t("writingTaskGradeReport.closeAssignment")
          : t("writingTaskGradeReport.openAssignment")}
      </AnchoredButton>
      <AssignmentConsultationPanel
        assignmentId={student!.assignmentId}
        opened={gradeContextDto.isPanelOpened}
      />
    </>
  );
};

const AnchoredButton = styled(Button)<{ offset: number }>`
  position: fixed;
  bottom: 44px;
  right: 44px;
  transition: right ${ANIMATION_DURATION}ms ease-in-out;
  width: 185px;

  &.opened {
    right: ${({ offset }) => `calc(${offset}px + 44px)`};
  }
`;
