import type { PropsWithChildren } from "react";
import { createContext } from "react";

import { useLoggedUser } from "../../../authentication/hooks/useLoggedUser";
import type { TeacherHomeroomsList } from "../../../domain/Teacher/TeacherDashboard";
import { useFlags } from "../App/FlagsProvider";
import { DEMO_TASKS_LIMIT } from "./DemoConstants";

export type DemoTeacherDashboardContext = {
  canCreateWritingTask: boolean;
};

export const DemoTeacherDashboardContext =
  createContext<DemoTeacherDashboardContext>({
    canCreateWritingTask: false,
  });

type Props = {
  homerooms: TeacherHomeroomsList;
} & PropsWithChildren;

export const DemoTeacherDashboardProvider = ({
  children,
  homerooms,
}: Props) => {
  const { demo } = useFlags();
  const { user } = useLoggedUser();

  const isOutsider = user.isDomainOutsider();
  const tasksCount = homerooms.reduce(
    (acc, group) => acc + group.tasksCount,
    0,
  );
  const canDemoUserCreateTask =
    demo && isOutsider && tasksCount < DEMO_TASKS_LIMIT;
  const canCreateWritingTask = !demo || canDemoUserCreateTask || !isOutsider;

  return (
    <DemoTeacherDashboardContext.Provider
      value={{
        canCreateWritingTask,
      }}
    >
      {children}
    </DemoTeacherDashboardContext.Provider>
  );
};
