import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

type FlagsConfig = {
  maintenance: boolean;
  printPdf: boolean;
  demo: boolean;
};

export const FlagsContext = createContext<FlagsConfig>({
  maintenance: false,
  printPdf: false,
  demo: false,
});

export enum ConfigCatKeys {
  maintenance = "maintenance",
  printPdf = "printpdf",
  demo = "demo",
}

export const useFlags = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlags cannot be used outside FlagsProvider");
  }

  return context;
};

type Props = {
  getFlag: (
    key: ConfigCatKeys,
    defaultValue: boolean,
  ) => { value: boolean; loading: boolean };
} & PropsWithChildren;

export const FlagsProvider = ({ children, getFlag }: Props) => {
  const maintenance = getFlag(ConfigCatKeys.maintenance, false).value;
  const printPdf = getFlag(ConfigCatKeys.printPdf, false).value;
  const demo = getFlag(ConfigCatKeys.demo, false).value;

  return (
    <FlagsContext.Provider
      value={{
        maintenance,
        printPdf,
        demo,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};
