import "react-loading-skeleton/dist/skeleton.css";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Header } from "@/application/ui/assignment.layout.ts";
import { Breadcrumbs } from "@/application/ui/lib/Beadcrumbs.tsx";

import type { WritingTaskDashboard } from "../../../domain/WritingTaskDashboard";
import { NavigationDropdown } from "../../lib/inputs/NavigationDropdown";
import { WRITING_TASK_GRADE_REPORT_BASE_URL } from "../../routing/constants";
import { useGrades } from "./GradeStates/useGrades";

type Props = {
  writingTaskDashboard: WritingTaskDashboard;
  writingTaskId: string;
  homeroomId: string;
};
export const GradeReportHeader = ({
  writingTaskDashboard,
  writingTaskId,
  homeroomId,
}: Props) => {
  const { t } = useTranslation();
  const { updateContext, setTaskId } = useGrades();
  const navigate = useNavigate();

  const handleWritingTaskSelection = (id: string) => {
    navigate(`${WRITING_TASK_GRADE_REPORT_BASE_URL}/${homeroomId}/${id}`);
    setTaskId(id);
    updateContext({});
  };

  const writingTaskOptions = writingTaskDashboard.tasks.map((task) => ({
    label: task.name,
    value: task.id,
  }));

  return (
    <Header>
      <Left>
        <Breadcrumbs
          entries={[
            { label: t("dashboard"), link: "/" },
            { label: writingTaskDashboard.group.name },
          ]}
        />
      </Left>

      <RightContainer>
        <NavigationDropdown
          onChange={(value) => handleWritingTaskSelection(value!)}
          selectedItemLabelPrefix={t("writingTaskGradeReport.assignmentFilter")}
          options={writingTaskOptions}
          name="selectedAssignment"
          value={writingTaskId}
          large
        />
      </RightContainer>
    </Header>
  );
};

const Left = styled.div`
  display: flex;
  flex: 1;
`;
const RightContainer = styled.div`
  display: flex;
  gap: 8px;
  align-self: center;
  align-items: center;
`;
