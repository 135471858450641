import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { WritingTask } from "@/application/domain/writingTask.ts";
import { AdvertiseSecuredMode } from "@/application/ui/pages/Dashboard/components/WritingTaskPanel/AdvertiseSecuredMode.tsx";

import { scrollbarStyle } from "../../../theme/Scrollbar";
import { theme } from "../../../theme/theme";
import { CharacterKeyboard } from "./CharacterKeyboard";

interface InformationPanelProps {
  numberOfWords: number;
  writingTask: WritingTask;
  onCharacterClick: (character: string) => void;
}

export const InformationPanel = ({
  numberOfWords,
  writingTask,
  onCharacterClick,
}: InformationPanelProps) => {
  const { t } = useTranslation();

  const diffWord = writingTask.numberOfWords
    ? writingTask.numberOfWords - numberOfWords
    : null;
  const isFar = diffWord;
  const isNear = diffWord && diffWord <= writingTask.numberOfWords! * 0.1;
  const isOver = diffWord !== null && diffWord <= 0;
  return (
    <Container>
      <Header>
        <Title>{t("writingAssignmentPage.sidePanelTitle")}</Title>
      </Header>
      <Content>
        <SubContainer>
          <DisplayInfoContainer>
            <DisplayInfoTitle>
              {t("writingAssignmentPage.wordCount")}
            </DisplayInfoTitle>
            <Badge>{numberOfWords}</Badge>
          </DisplayInfoContainer>
          <DisplayInfoContainer>
            <DisplayInfoTitle>
              {t("writingAssignmentPage.limmit")}
            </DisplayInfoTitle>
            <Badge>
              {writingTask.numberOfWords ? writingTask.numberOfWords : "-"}
            </Badge>
          </DisplayInfoContainer>
          <DisplayInfoContainer>
            <DisplayInfoTitle>
              {t("writingAssignmentPage.wordsLeft")}
            </DisplayInfoTitle>
            <Badge
              className={clsx({
                far: isFar,
                near: isNear,
                over: isOver,
              })}
            >
              {writingTask.numberOfWords ? diffWord : "-"}
            </Badge>
          </DisplayInfoContainer>
        </SubContainer>
        <Divider />
        <DisplayInfoContainer>
          <DisplayInfoTitle>{t("taskTitle")}</DisplayInfoTitle>
          <DisplayInfoText>{writingTask.name}</DisplayInfoText>
        </DisplayInfoContainer>
        <DisplayInfoContainer>
          <DisplayInfoTitle>{t("instruction")}</DisplayInfoTitle>
          <DisplayInfoText>{writingTask.instructions}</DisplayInfoText>
        </DisplayInfoContainer>
        <DisplayInfoContainer>
          <CharacterKeyboard insertCharacter={onCharacterClick} />
        </DisplayInfoContainer>
        {writingTask.taskType === "summative" && (
          <AdvertiseSecuredMode
            color="warning"
            closable={false}
            withTooltip={true}
          />
        )}
      </Content>
    </Container>
  );
};

const Header = styled.section`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  background: ${theme.colors.gray[50]};
`;

const Title = styled.h2`
  color: ${theme.colors.gray[900]};
  text-align: center;
  ${theme.typography.lg.bold}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 24px 24px 16px;

  ${scrollbarStyle()}
`;

const Container = styled.div`
  background-color: ${theme.colors.gray[50]};
  border-left: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadowAndBlur.xxlarge};
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  height: 100vh;
  position: sticky;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  padding: 0 10px 10px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`;

const DisplayInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const DisplayInfoTitle = styled.span`
  ${theme.typography.sm.medium};
  font-weight: 500;
`;

const DisplayInfoText = styled.span`
  ${theme.typography.sm.regular};
  font-weight: 400;
`;

const Badge = styled.span`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  width: fit-content;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.gray[100]};
  color: ${theme.colors.gray[700]};

  &.far {
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.primary[600]};
  }
  &.near {
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.yellow[400]};
  }
  &.over {
    color: ${theme.colors.base.white};
    background-color: ${theme.colors.error[500]};
  }
`;
