import { Tooltip } from "react-tooltip";

import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle.ts";

import { ContextualMenuAction } from "./WritingTaskContextMenu.tsx";

export type ContextMenuAction = {
  label: string;
  children: string | JSX.Element;
  callback: () => void;
  disabled?: boolean;
  className?: string;
  tooltipMessage?: string;
  keepOpenOnClick?: boolean;
};

export type MenuActionProps = {
  action: ContextMenuAction;
  closeContextMenu: () => void;
};

export const MenuAction = ({ action, closeContextMenu }: MenuActionProps) => {
  const { label, disabled, callback, children, className, tooltipMessage } =
    action;
  return (
    <ContextualMenuAction
      key={label}
      className={className}
      variant="text"
      hierarchy="neutral"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        callback();
        closeContextMenu();
      }}
    >
      {className && tooltipMessage && disabled && (
        <Tooltip
          anchorSelect={"." + className}
          place="bottom"
          style={tooltipStyle}
        >
          {tooltipMessage}
        </Tooltip>
      )}
      {children}
    </ContextualMenuAction>
  );
};
