import { createContext, type PropsWithChildren, useState } from "react";
import { useParams } from "react-router-dom";

import type { GradedStudent } from "../../../../domain/gradedStudents";

type GradesFilterProps = {
  letterMode?: boolean;
  panelOpened?: boolean;
  subcriteriaMode?: boolean;
  studentMode?: boolean;
  criteria?: string;
};

export class GradesFilter {
  constructor(
    private letterMode: boolean = true,
    private panelOpened: boolean = false,
    private subcriteriaMode: boolean = false,
    private studentMode: boolean = false,
    private criteria: string = "all",
  ) {}

  public get isLetterMode() {
    return this.letterMode;
  }

  public get isPanelOpened() {
    return this.panelOpened;
  }
  public get isSubcriteriaMode() {
    return this.subcriteriaMode;
  }
  public get isStudentMode() {
    return this.studentMode;
  }
  public get selectedCriteria() {
    return this.criteria;
  }
}

export type GradeContextProps = {
  updateContext: (context: GradesFilterProps) => void;
  gradeContextDto: GradesFilter;
  student: GradedStudent | undefined;
  taskId: string;
  setStudent: (student?: GradedStudent) => void;
  setTaskId: (id: string) => void;
};

export const GradeContext = createContext<GradeContextProps>({
  updateContext: (_: GradesFilterProps) => {},
  gradeContextDto: new GradesFilter(),
  student: undefined,
  setStudent: (_?: GradedStudent) => {},
  setTaskId: (_: string) => {},
  taskId: "",
});

export const GradeProvider = ({ children }: PropsWithChildren) => {
  const { writingTaskId } = useParams();

  const [filter, setFilter] = useState<GradesFilter>(new GradesFilter());
  const [student, setStudent] = useState<GradedStudent | undefined>(undefined);
  const [taskId, setTaskId] = useState<string>(writingTaskId!);

  const updateContext = (newFilter: GradesFilterProps) => {
    setFilter(
      new GradesFilter(
        newFilter.letterMode ?? filter.isLetterMode,
        newFilter.panelOpened ?? filter.isPanelOpened,
        newFilter.subcriteriaMode ?? filter.isSubcriteriaMode,
        newFilter.studentMode ?? filter.isStudentMode,
        newFilter.criteria ?? filter.selectedCriteria,
      ),
    );
  };

  return (
    <GradeContext.Provider
      value={{
        updateContext,
        gradeContextDto: filter,
        student,
        taskId,
        setStudent,
        setTaskId,
      }}
    >
      {children}
    </GradeContext.Provider>
  );
};
