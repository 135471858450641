import styled from "styled-components";

import type { WritingTaskAssignments } from "../../../../../domain/WritingTaskAssignments";
import { theme } from "../../../../theme/theme";
import { RevisableAndGradableAssignment } from "../../../Revision/service/RevisableAndGradableAssignment";
import { StudentAssignmentSidebar } from "../../../StudentAssignment/StudentAssignmentSidebar";
import { PdfCommentsSection } from "./PdfCommentsSection";
import { PdfHeader } from "./PdfHeader";
import { PdfText } from "./PdfText";

type Props = {
  taskWithAssignments: WritingTaskAssignments;
};

export const AssignmentsPdf = ({ taskWithAssignments }: Props) => {
  const revisableAssignments = taskWithAssignments.assignments.map((x) =>
    RevisableAndGradableAssignment.init(
      x,
      taskWithAssignments.writingTask.gradingScaleType,
    ),
  );

  return revisableAssignments.map((x) => (
    <>
      <PageContainer key={`page${x.assignment.id}`}>
        <PdfHeader
          studentName={x.assignment.studentInformation.name.fullName()}
          taskName={taskWithAssignments.writingTask.name}
          groupName={taskWithAssignments.homeroom.name}
        />
        <EvaluationContainer>
          <PdfText
            assignmentState={x.revisedAssignmentContentState}
            comments={x.assignment.comments}
          />
          <PdfCommentsSection comments={x.assignment.comments} />
        </EvaluationContainer>
      </PageContainer>
      <PageContainer key={`correction${x.assignment.id}`}>
        <PdfHeader
          studentName={x.assignment.studentInformation.name.fullName()}
          taskName={taskWithAssignments.writingTask.name}
          groupName={taskWithAssignments.homeroom.name}
        />
        <Centered>
          <StudentAssignmentSidebar
            pdf
            assignment={{
              ...x.assignment,
              gradingScaleType:
                taskWithAssignments.writingTask.gradingScaleType,
              writingTaskInformation: {
                ...taskWithAssignments.writingTask,
                instructions: "",
              },
            }}
          />
        </Centered>
      </PageContainer>
    </>
  ));
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const EvaluationContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 320mm;
  white-space: pre-line;
  color: black;
  page-break-after: always;
  padding: 40px;
  gap: 24px;
  background-color: ${theme.colors.gray[25]};
`;
