import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as PrinterIcon } from "@/assets/printer.svg";

import { Button } from "../../../../lib/button/Button";
import { Checkbox } from "../../../../lib/checkbox/Checkbox";
import { BaseModal } from "../../../../lib/form/BaseModal";
import { FeaturedIcon } from "../../../../lib/icon/FeaturedIcon";
import { theme } from "../../../../theme/theme";

export interface PrintWritingTaskAssignmentsModalRef {
  display: (taskId: string) => void;
}

export const PrintWritingTaskAssignmentsModal = React.forwardRef(
  (_, ref: Ref<PrintWritingTaskAssignmentsModalRef>) => {
    const [open, isOpen] = useState<boolean>(false);

    const [writingTaskId, setWritingTaskId] = useState<string | undefined>(
      undefined,
    );

    useImperativeHandle(ref, () => ({
      display: (taskId: string) => {
        setWritingTaskId(taskId);
        isOpen(true);
      },
    }));

    const handleAction = () => {
      isOpen(false);
    };

    const { t } = useTranslation();

    return (
      <BaseModal
        open={open}
        icon={
          <FeaturedIcon color="success" size={20}>
            <PrinterIcon />
          </FeaturedIcon>
        }
        onClose={handleAction}
        title={t("printTaskAssignments.title")}
        description={
          <PdfFilters>
            <span>{t("printTaskAssignments.allStudents")}</span>
            <Checkbox
              checked={true}
              selectedColor={theme.colors.base.white}
              borderColor={theme.colors.primary[700]}
              backgroundSelectedColor={theme.colors.primary[700]}
              onChange={() => {}}
            />
          </PdfFilters>
        }
      >
        <Button hierarchy="neutral" onClick={handleAction}>
          {t("cancel")}
        </Button>
        <a
          target="_blank"
          href={`/professeur/pdf/${writingTaskId}`}
          rel="noreferrer"
        >
          <Button
            hierarchy="success"
            variant="contained"
            onClick={handleAction}
          >
            {t("confirm")}
          </Button>
        </a>
      </BaseModal>
    );
  },
);

const PdfFilters = styled.div`
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: ${theme.radius.large};
  padding: 24px;
  display: flex;
  justify-content: space-between;

  ${theme.typography.sm.semibold};
  color: ${theme.colors.gray[600]};
`;
