import clsx from "clsx";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";
import type { CorrectionMode } from "@/application/ui/pages/Revision/RevisionStates/AssignmentsProvider.tsx";

import { theme } from "../../../../../theme/theme";
import { CommentPopup } from "../../RevisionPopup/CommentPopup";
import { useRevisionPopup } from "../../RevisionPopup/useRevisionPopup";
import { getRects } from "./GetRectanglesAlgo";

interface Props {
  readOnly: boolean;
  comment: TeacherComment;
  onDelete: () => void;
  onChange: (comment: TeacherComment) => void;
  parent: HTMLDivElement;
  parentCoordinates: DOMRect;
  mode: CorrectionMode;
  pdfCommentIndex?: number;
}

export const HighlightedComment = ({
  readOnly,
  comment,
  onDelete,
  onChange,
  parent,
  parentCoordinates,
  mode,
  pdfCommentIndex,
}: Props) => {
  const validateEmptyComment = () => {
    if (comment.text.trim() === "") onDelete();
  };
  const { popupIsVisible, showPopupInViewMode, closePopup } = useRevisionPopup({
    popupIsVisibleBaseValue: comment.text.trim() === "",
    afterClosingAction: validateEmptyComment,
  });

  const rectangles = getRects(parent, comment.startIndex, comment.endIndex);

  return (
    <Popover
      isOpen={popupIsVisible}
      positions={["right", "left", "top"]}
      padding={10}
      content={
        <CommentPopup
          readOnly={readOnly}
          comment={comment}
          onDelete={onDelete}
          onChange={onChange}
          onClose={closePopup}
        />
      }
    >
      <HighlightContainer
        data-testid={`comment_${comment.id}`}
        onClick={showPopupInViewMode}
        className={clsx({ isCommentMode: mode === "comment" })}
      >
        {rectangles.map((r, i) => (
          <g key={`${comment.id}_${i}`}>
            <Highlight
              x={r.left - parentCoordinates.left}
              y={r.top - parentCoordinates.top}
              width={r.width}
              height={r.height}
              className={clsx({
                isSelected: popupIsVisible,
                isCommentMode: mode === "comment",
              })}
            />
            {pdfCommentIndex && i === rectangles.length - 1 && (
              <TextLabel
                x={r.left - parentCoordinates.left + r.width + 8}
                y={r.top - parentCoordinates.top - 8}
              >
                {pdfCommentIndex}
              </TextLabel>
            )}
          </g>
        ))}
      </HighlightContainer>
    </Popover>
  );
};

const Highlight = styled.rect`
  fill: rgba(127, 86, 217, 0.1);

  &.isCommentMode {
    fill: rgba(127, 86, 217, 0.2);
  }

  &.isSelected {
    fill: rgba(127, 86, 217, 0.4);
  }
`;

const HighlightContainer = styled.g`
  &.isCommentMode {
    pointer-events: auto;
    cursor: pointer;
  }
`;

const TextLabel = styled.text`
  ${theme.typography.md.bold};
  fill: ${theme.colors.base.black};
  text-anchor: middle;
  dominant-baseline: middle;
  pointer-events: none;
`;
