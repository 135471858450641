import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AssignmentStatus } from "../../../../../domain/Assignment";
import { theme } from "../../../../theme/theme";

interface Props {
  status: AssignmentStatus;
}

const StatusContainer = styled.div<{ $bgcolor: string; $color: string }>`
  background-color: ${(props) => props.$bgcolor};
  color: ${(props) => props.$color};
  padding: 10px;
  border-radius: ${theme.radius.round};
  display: flex;
  ${theme.typography.xs.regular}
`;

const ColoredDot = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: ${(props) => props.$color};
  align-self: center;
  border-radius: ${theme.radius.round};
`;

export const WritingTaskStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  let color;
  let bgColor;
  let dotColor;
  switch (status) {
    case AssignmentStatus.CORRECTED:
      color = theme.colors.indigo[700];
      bgColor = theme.colors.indigo[50];
      dotColor = theme.colors.indigo[500];
      break;
    case AssignmentStatus.GRADED:
      color = theme.colors.success[700];
      bgColor = theme.colors.success[50];
      dotColor = theme.colors.success[500];
      break;
    case AssignmentStatus.WAIT_FOR_CORRECTION:
      color = theme.colors.warning[700];
      bgColor = theme.colors.warning[50];
      dotColor = theme.colors.warning[300];
      break;
    case AssignmentStatus.WRITING_IN_PROGRESS:
      color = theme.colors.orangeDark[600];
      bgColor = theme.colors.orangeDark[50];
      dotColor = theme.colors.warning[500];
      break;
    case AssignmentStatus.CORRECTION_REJECTED:
      color = theme.colors.error[700];
      bgColor = theme.colors.error[50];
      dotColor = theme.colors.error[400];
      break;
    case AssignmentStatus.NULL:
      color = theme.colors.base.black;
      bgColor = "transparent";
      dotColor = "transparent";
      break;
    default:
      color = theme.colors.gray[700];
      bgColor = theme.colors.gray[100];
      dotColor = theme.colors.gray[500];
      break;
  }

  return (
    <StatusContainer $color={color} $bgcolor={bgColor}>
      <ColoredDot $color={dotColor} />
      {t(status)}
    </StatusContainer>
  );
};
