import type { PropsWithChildren, ReactNode } from "react";
import { Children } from "react";
import styled from "styled-components";

import { theme } from "../../../../theme/theme";

type Props = PropsWithChildren<{
  title?: string;
  action?: ReactNode;
  variant?: "small" | "regular";
}>;

export const SidebarBox = ({
  title,
  children,
  action,
  variant = "regular",
}: Props) => {
  const hasChildren = Children.count(children) > 0;

  return (
    <Container>
      <TextAreaContainer>
        {title && (
          <Header $withborder={hasChildren}>
            <TitleContainer>{title}</TitleContainer>
            <Action>{action}</Action>
          </Header>
        )}
      </TextAreaContainer>
      {children && <Content className={variant}>{children}</Content>}
    </Container>
  );
};

const Container = styled.div`
  border: ${theme.borders.primary};
  background-color: ${theme.colors.base.white};
  border-radius: ${theme.radius.large};
  width: 100%;
  box-sizing: border-box;
`;

const Header = styled.div<{ $withborder: boolean }>`
  > .visibleIcon {
    opacity: 0;
  }

  &:hover {
    > .visibleIcon {
      opacity: 100%;
    }
  }

  font-weight: ${theme.fontWeight.regular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  background-color: ${theme.colors.gray[100]};
  border-radius: ${theme.radius.large};
  ${(props) =>
    props.$withborder &&
    `
    border-radius: ${theme.radius.large} ${theme.radius.large} 0 0;
    border-bottom: 1px solid ${theme.colors.gray[300]};
  `};
`;

const TitleContainer = styled.div`
  ${theme.typography.xs.bold}
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Action = styled.span`
  ${theme.typography.xs.semibold}
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.radius.medium};
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;

  &.small {
    padding: 8px 14px;
  }

  &.regular {
    padding: 14px;
  }
`;
