import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuthenticatingUser } from "@/application/authentication/hooks/useAuthenticatingUser.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { ReactComponent as AzureLogo } from "@/assets/azure-logo.svg";

import { ReactComponent as EmiliaLogo } from "../../../../staticAssets/emilia-logo.svg";
import { theme } from "../../theme/theme";
import { MaintenanceBanner } from "../Maintenance/MaintenanceBanner";

export const LoginPage = () => {
  const { t } = useTranslation();

  const { login } = useAuthenticatingUser();

  return (
    <>
      <MaintenanceBanner />
      <Container>
        <Content>
          <WelcomeContent>
            <EmiliaLogo width={250} height={65} title={t("welcome")} />
            <WelcomeMessage>{t("welcomeSubMessage")}</WelcomeMessage>
          </WelcomeContent>
          <Button
            onClick={() => {
              void login();
            }}
            hierarchy="neutral"
          >
            <ButtonContent>
              <AzureLogo width={24} height={24} />
              {t("signInWithAzure")}
            </ButtonContent>
          </Button>
        </Content>
      </Container>
    </>
  );
};

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 32px;
  width: 30%;
`;

const WelcomeMessage = styled.p`
  color: ${theme.colors.gray[600]};
  ${theme.typography.md.regular}
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const WelcomeContent = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
