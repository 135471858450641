import { useMutation } from "@tanstack/react-query";

import { AssignmentClient } from "../../../../../client/AssignmentClient";

type Props = {
  onSuccess?: () => Promise<void>;
  onError?: () => void;
};

export const useSubmitTextAssignment = ({ onSuccess, onError }: Props) => {
  const { mutate: submitTextAssignment, isLoading } = useMutation(
    (data: { taskId: string; text: string }) =>
      AssignmentClient.submitText(data.taskId, data.text),
    {
      onSuccess,
      onError,
    },
  );

  return { submitTextAssignment, isLoading };
};
