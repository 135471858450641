import styled from "styled-components";

import { theme } from "../../../../../theme/theme";

export const Container = styled.span`
  &.isClickable {
    cursor: pointer;
  }
`;

export const Mark = styled.mark`
  &.unspecified {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: ${theme.colors.gray[400]};
  }

  &.penalized {
    &.spelling,
    &.vocabulary,
    &.grammar,
    &.syntax,
    &.punctuation {
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
    }

    &.syntax::before,
    &.punctuation::before {
      content: "[";
      ${theme.typography.xl.semibold}
    }

    &.syntax::after,
    &.punctuation::after {
      content: "]";
      ${theme.typography.xl.semibold}
    }

    &.spelling {
      text-decoration-color: ${theme.colors.category.spelling};
    }

    &.grammar {
      text-decoration-color: ${theme.colors.category.grammar};
    }

    &.vocabulary {
      text-decoration-color: ${theme.colors.category.vocabulary};
    }

    &.syntax {
      text-decoration-color: ${theme.colors.category.syntax};
    }

    &.punctuation {
      text-decoration-color: ${theme.colors.category.punctuation};
    }

    &.syntax::before,
    &.syntax::after {
      color: ${theme.colors.category.syntax};
    }

    &.punctuation::before,
    &.punctuation::after {
      color: ${theme.colors.category.punctuation};
    }
  }

  &.isCommentMode {
    &.spelling {
      text-decoration-color: color-mix(
        in srgb,
        ${theme.colors.category.spelling},
        transparent 50%
      );
    }

    &.grammar {
      text-decoration-color: color-mix(
        in srgb,
        ${theme.colors.category.grammar},
        transparent 50%
      );
    }

    &.vocabulary {
      text-decoration-color: color-mix(
        in srgb,
        ${theme.colors.category.vocabulary},
        transparent 50%
      );
    }

    &.syntax {
      text-decoration-color: color-mix(
        in srgb,
        ${theme.colors.category.syntax},
        transparent 50%
      );
    }

    &.punctuation {
      text-decoration-color: color-mix(
        in srgb,
        ${theme.colors.category.punctuation},
        transparent 50%
      );
    }

    &.syntax::before,
    &.syntax::after {
      color: color-mix(
        in srgb,
        ${theme.colors.category.syntax},
        transparent 50%
      );
    }

    &.punctuation::before,
    &.punctuation::after {
      color: color-mix(
        in srgb,
        ${theme.colors.category.punctuation},
        transparent 50%
      );
    }
  }

  &.not-penalized {
    &.syntax::before,
    &.punctuation::before {
      content: "[";
      ${theme.typography.xl.semibold}
    }

    &.syntax::after,
    &.punctuation::after {
      content: "]";
      ${theme.typography.xl.semibold}
    }

    &.spelling,
    &.vocabulary,
    &.grammar,
    &.syntax,
    &.punctuation {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-decoration-thickness: 3px;
      text-decoration-color: ${theme.colors.gray[400]};
    }
    color: ${theme.colors.gray[400]};
  }

  &.whitespace {
    &.spelling::before,
    &.grammar::before,
    &.vocabulary::before {
      content: ".";
      display: block;
      color: transparent;
      line-height: ${theme.typography.lineHeight.textCorrector};
    }

    &.vocabulary::before {
      text-decoration-color: ${theme.colors.category.vocabulary};
    }

    &.grammar::before {
      text-decoration-color: ${theme.colors.category.grammar};
    }

    &.spelling::before {
      text-decoration-color: ${theme.colors.category.spelling};
    }
  }
`;

export const RevisionBlock = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  vertical-align: bottom;
  overflow: visible;
`;

export const Revised = styled.span`
  line-height: 0px;
  margin-bottom: -8px;
  padding-left: 4px;
  padding-right: 4px;
  width: fit-content;
  user-select: none;

  &.spelling {
    color: ${theme.colors.category.spelling};
  }

  &.grammar {
    color: ${theme.colors.category.grammar};
  }

  &.vocabulary {
    color: ${theme.colors.category.vocabulary};
  }

  &.syntax {
    color: ${theme.colors.category.syntax};
  }

  &.punctuation {
    color: ${theme.colors.category.punctuation};
  }

  &.not-penalized {
    color: ${theme.colors.gray[400]};
  }
`;
