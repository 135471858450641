import type { EvaluationCriteria } from "@emilia/backend/src/common/grade/globalGrade/EvaluationCriteria";
import type {
  Grade,
  GradeLetter,
} from "@emilia/backend/src/common/grade/model/Grade";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import type { ValuedGrade } from "@emilia/backend/src/common/grade/model/ValuedGrade";

import type { Assignment, TeacherComment } from "../../../../domain/Assignment";
import { AssignmentStatus } from "../../../../domain/Assignment";
import type { GradedAssignment } from "../../../../domain/GradedAssignment";
import { GradingScaleModel } from "../../../../domain/GradingScale/GradingScaleModel";
import type { AudioRecord } from "../../../../domain/Record/AudioRecord";
import { RevisedAssignmentContentState } from "./RevisedAssignmentContentState";

export class RevisableAndGradableAssignment {
  private _comment: string;
  constructor(
    public assignment: Assignment,
    public readonly revisedAssignmentContentState: RevisedAssignmentContentState,
    public readonly gradingScaleModel: GradingScaleModel,
    public audioRecord: AudioRecord | undefined = undefined,
  ) {
    this._comment = assignment?.comment ?? "";
  }

  static init(
    assignment: Assignment,
    gradingScale: GradingScaleType,
    audioRecord: AudioRecord | undefined = undefined,
  ): RevisableAndGradableAssignment {
    const revisedAssignmentContentState =
      RevisedAssignmentContentState.init(assignment);
    const gradingScaleModel = GradingScaleModel.init(assignment, gradingScale);

    return new RevisableAndGradableAssignment(
      assignment,
      revisedAssignmentContentState,
      gradingScaleModel,
      audioRecord,
    );
  }

  get comment(): string {
    return this._comment;
  }

  get status(): AssignmentStatus {
    return this.assignment.status;
  }

  get audioUrl(): string | undefined {
    if (this.audioRecord) {
      return this.audioRecord.url;
    }

    return undefined;
  }

  updateAudioRecord(audioRecord: AudioRecord | undefined) {
    return new RevisableAndGradableAssignment(
      this.assignment,
      this.revisedAssignmentContentState,
      this.gradingScaleModel,
      audioRecord,
    );
  }

  updateContentState(
    revisedAssignmentContentState: RevisedAssignmentContentState,
  ): RevisableAndGradableAssignment {
    return new RevisableAndGradableAssignment(
      this.assignment,
      revisedAssignmentContentState,
      this.gradingScaleModel.updateComputedGrades(
        revisedAssignmentContentState,
      ),
      this.audioRecord,
    );
  }

  updateComments(comments: TeacherComment[]): RevisableAndGradableAssignment {
    return new RevisableAndGradableAssignment(
      {
        ...this.assignment,
        comments,
      },
      this.revisedAssignmentContentState,
      this.gradingScaleModel,
      this.audioRecord,
    );
  }

  updateCriterionManually(criterion: EvaluationCriterion, grade: GradeLetter) {
    return new RevisableAndGradableAssignment(
      this.assignment,
      this.revisedAssignmentContentState,
      this.gradingScaleModel.updateCriterionManually(criterion, grade),
      this.audioRecord,
    );
  }

  isGradeLetterForCriterion(
    criterion: EvaluationCriterion,
    possibleGrade: GradeLetter,
    evaluationCriteria: EvaluationCriteria,
  ) {
    return this.gradingScaleModel.isGradeLetterForCriterion(
      criterion,
      possibleGrade,
      evaluationCriteria,
    );
  }

  getGlobalGrade(evaluationCriteria: EvaluationCriteria): ValuedGrade | null {
    return this.gradingScaleModel.getGlobalGrade(evaluationCriteria);
  }

  isFullyGraded(evalCriteria: EvaluationCriteria): boolean {
    return this.gradingScaleModel.evaluationIsComplete(evalCriteria);
  }

  isSyntaxGraded(evaluatedCriteria: EvaluationCriteria): boolean {
    return this.gradingScaleModel.isGraded(
      EvaluationCriterion.SYNTAX,
      evaluatedCriteria,
    );
  }

  updateComment(comment: string) {
    return new RevisableAndGradableAssignment(
      {
        ...this.assignment,
        comment,
      },
      this.revisedAssignmentContentState,
      this.gradingScaleModel,
      this.audioRecord,
    );
  }

  removeRecord() {
    return new RevisableAndGradableAssignment(
      {
        ...this.assignment,
        recordUrl: undefined,
      },
      this.revisedAssignmentContentState,
      this.gradingScaleModel,
      undefined,
    );
  }

  toGradedAssignment(evaluationCriteria: EvaluationCriteria): GradedAssignment {
    if (!this.isFullyGraded(evaluationCriteria)) {
      throw new Error("Assignment needs to be fully graded");
    }

    const { revisions, revisedText } =
      this.revisedAssignmentContentState.toRevisedTextWithRevisions();

    const criterionMapping: Record<string, EvaluationCriterion> = {
      C1: EvaluationCriterion.ADAPTATION,
      C2: EvaluationCriterion.COHERENCE,
      C3: EvaluationCriterion.VOCABULARY,
      C4: EvaluationCriterion.SYNTAX,
      C5: EvaluationCriterion.ORTHOGRAPHY,
    };

    const gradedCriteria: Partial<Record<EvaluationCriterion, Grade>> = {};

    Object.keys(evaluationCriteria).forEach((evalCriterion) => {
      if (evalCriterion) {
        const criterion = criterionMapping[evalCriterion];
        if (criterion) {
          const grade = this.gradingScaleModel.getValuedGradeFor(
            criterion,
            evaluationCriteria,
          )?.grade;
          if (grade) {
            gradedCriteria[criterion] = grade;
          }
        }
      }
    });
    return {
      id: this.assignment.id,
      studentInformation: this.assignment.studentInformation,
      status: AssignmentStatus.GRADED,
      revisedText,
      revisions,
      comments: this.assignment.comments,
      gradedCriteria,
      globalGrade: this.getGlobalGrade(evaluationCriteria)!,
      comment: this.assignment?.comment ?? "",
      recordUrl: this.assignment.recordUrl,
    };
  }
}
