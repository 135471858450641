import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Checkbox } from "@/application/ui/lib/checkbox/Checkbox.tsx";
import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as EditIcon } from "@/assets/edit-square.svg";
import { ReactComponent as FolderIcon } from "@/assets/folder.svg";

import { Dropdown } from "../../lib/inputs/Dropdown.tsx";
import type { CreationContextMenuAction } from "./components/CreationContextMenu.tsx";
import { TeacherDashboardContextMenu } from "./components/CreationContextMenu.tsx";
import { useDemoTeacherDashboard } from "./useDemoeacherDashboard.ts";
import { useTeacherDashboard } from "./useTeacherDashboard.ts";

export const REVISION_PAGE_URL = "/professeur/revision";

export const TeacherDashboardFilters = () => {
  const { t } = useTranslation();
  const key = "homeroomId";
  const persistedHomeroomId = sessionStorage.getItem(key) ?? "";

  const {
    openCreationFor,
    setIsCreatingSection,
    selectedHomeroomId,
    homerooms,
    setHomeroomId,
    includeMasked,
    setIncludeMasked,
  } = useTeacherDashboard();

  const { canCreateWritingTask } = useDemoTeacherDashboard();

  const changeHomeroomId = (homeroomId: string) => {
    sessionStorage.setItem(key, homeroomId);
    setHomeroomId(homeroomId);
  };

  if (homerooms && homerooms.length && persistedHomeroomId === "") {
    changeHomeroomId(homerooms[0].id);
  }

  const taskCreationDisabled = !canCreateWritingTask;
  const creationActions: CreationContextMenuAction[] = [
    {
      label: t("newTask"),
      disabled: taskCreationDisabled,
      callback: () => openCreationFor(selectedHomeroomId),
      tooltipMessage: taskCreationDisabled ? t("demoLimit") : undefined,
      children: (
        <>
          <EditIcon />
          {t("newTask")}
        </>
      ),
    },
    {
      label: t("sections.newSection"),
      callback: () => setIsCreatingSection(true),
      children: (
        <>
          <FolderIcon />
          {t("sections.newSection")}
        </>
      ),
    },
  ];

  const defaultId =
    selectedHomeroomId !== ""
      ? homerooms.filter((h) => selectedHomeroomId === h.id)[0].id
      : homerooms[0].id;

  return (
    <Header>
      <StyledDropdown
        options={homerooms.map((x) => ({
          label: x.name,
          value: x.id,
        }))}
        defaultValue={defaultId}
        onChange={(x) => {
          changeHomeroomId(x);
        }}
        selectedItemLabelPrefix={t("HomeroomGroupsDropdown")}
        edges="round"
      />
      <RigthSideGrouping>
        <CheckboxContainer>
          <Checkbox
            checked={includeMasked}
            selectedColor={theme.colors.base.white}
            borderColor={theme.colors.primary[700]}
            backgroundSelectedColor={theme.colors.primary[700]}
            onChange={() => {
              setIncludeMasked(!includeMasked);
            }}
          />
          <span>{t("includeHiddenTask")}</span>
        </CheckboxContainer>

        <TeacherDashboardContextMenu actions={creationActions} />
      </RigthSideGrouping>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f4f7;
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-left: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${theme.typography.sm.semibold}
  align-items: center;
  gap: 8px;
`;

const RigthSideGrouping = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
