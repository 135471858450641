import { Page } from "@/application/ui/lib/Page.tsx";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage.tsx";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage.tsx";

import { TeacherDashboardSections } from "./components/TeacherDashboardSections.tsx";
import { DemoTeacherDashboardProvider } from "./DemoTeacherDashboardProvider.tsx";
import { useTeacherHomerooms } from "./hooks/useTeacherHomerooms.ts";
import { NoGroupsPage } from "./NoGroupsPage.tsx";
import { TeacherDashboardFilters } from "./TeacherDashboardFilters.tsx";
import { TeacherDashboardProvider } from "./TeacherDashboardProvider.tsx";

export const REVISION_PAGE_URL = "/professeur/revision";

export const TeacherDashboard = () => {
  const { homerooms, isError, isLoading } = useTeacherHomerooms();

  if (isLoading) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  if (isError) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!homerooms || homerooms.length === 0) {
    return (
      <Page>
        <NoGroupsPage />
      </Page>
    );
  }

  return (
    <Page>
      <TeacherDashboardProvider homerooms={homerooms}>
        <DemoTeacherDashboardProvider homerooms={homerooms}>
          <TeacherDashboardFilters />
          <TeacherDashboardSections />
        </DemoTeacherDashboardProvider>
      </TeacherDashboardProvider>
    </Page>
  );
};
