import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { ConsultationAssignment } from "@/application/domain/Assignment.ts";
import { AudioPlayer } from "@/application/ui/lib/recording/components/AudioPlayer.tsx";
import { ReadOnlyGradingScale } from "@/application/ui/pages/Revision/components/GradingScale/ReadOnlyGradingScale.tsx";
import { CommentTextArea } from "@/application/ui/pages/Revision/components/Sidebar/CommentTextArea/CommentTextArea.tsx";
import { ErrorSummary } from "@/application/ui/pages/Revision/components/Sidebar/ErrorSummary/ErrorSummary.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";
import { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { ReadOnlyGlobalGrade } from "@/application/ui/pages/StudentAssignment/ReadOnlyGlobalGrade.tsx";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";

import { theme } from "../../theme/theme";

type Props = {
  assignment: ConsultationAssignment;
  pdf?: boolean;
};

export const StudentAssignmentSidebar = ({
  assignment,
  pdf = false,
}: Props) => {
  const { t } = useTranslation();

  const revisedAssignmentContentState = useMemo(
    () => RevisedAssignmentContentState.init(assignment),
    [assignment],
  );

  return (
    revisedAssignmentContentState && (
      <Container className={clsx({ pdf })}>
        {assignment.recordUrl && !pdf && (
          <SidebarBox title={t("audioComment")}>
            <AudioPlayer url={assignment.recordUrl} />
          </SidebarBox>
        )}

        <CommentTextArea
          readonly
          key={assignment.id}
          comment={assignment.comment || ""}
        />
        <ReadOnlyGradingScale pdf={pdf} assignment={assignment} />
        <ErrorSummary
          pdf={pdf}
          revisedAssignmentContentState={revisedAssignmentContentState}
          commentsCount={assignment.comments.length}
        />

        <ReadOnlyGlobalGrade globalGrade={assignment?.globalGrade || null} />
      </Container>
    )
  );
};

const Container = styled.div`
  background-color: ${theme.colors.gray[100]};
  border-left: 1px solid ${theme.colors.gray[200]};
  box-shadow: ${theme.shadowAndBlur.xxlarge};
  &.pdf {
    background-color: ${theme.colors.base.white};
    box-shadow: none;
    border: 1px solid ${theme.colors.gray[200]};
  }

  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  padding: 24px;
  height: 100%;
  position: sticky;
  overflow-y: auto;
  right: 0;
  gap: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${scrollbarStyle()}
`;
