import styled from "styled-components";

import type { TeacherComment } from "../../../../../domain/Assignment";
import { theme } from "../../../../theme/theme";
import { TextCorrector } from "../../../Revision/components/TextCorrector/TextCorrector";
import type { RevisedAssignmentContentState } from "../../../Revision/service/RevisedAssignmentContentState";

type Props = {
  assignmentState: RevisedAssignmentContentState;
  comments: TeacherComment[];
};

export const PdfText = ({ assignmentState, comments }: Props) => {
  return (
    <Corrector>
      <TextCorrector
        pdf={true}
        revisedAssignmentContentState={assignmentState}
        comments={comments}
        onContentStateChange={() => {}}
        onCommentsChange={() => {}}
        readOnly={true}
      />
    </Corrector>
  );
};

const Corrector = styled.div`
  width: 75%;
  line-height: ${theme.typography.lineHeight.textCorrector};
`;
