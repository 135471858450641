import "react-loading-skeleton/dist/skeleton.css";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { useGrades } from "@/application/ui/pages/GradeReport/GradeStates/useGrades.ts";

import type { GradedStudents } from "../../../domain/gradedStudents";
import { SlidingToggleButton } from "../../lib/button/SlidingToggleButton";
import { Dropdown } from "../../lib/inputs/Dropdown";
import { EvaluatedCriteriaDropdown } from "./components/EvaluatedCriteriaDropdown";
import type { SortedGrades } from "./WritingTaskGradeReport";

type Props = {
  selectStudent: (id: string) => void;
  sortedGrades: SortedGrades;
  gradedStudents: GradedStudents | undefined;
};

export const GradeReportFilters = ({
  selectStudent,
  sortedGrades,
  gradedStudents,
}: Props) => {
  const { t } = useTranslation();
  const { gradeContextDto, updateContext, student, setStudent } = useGrades();

  useMemo(() => {
    if (
      gradedStudents &&
      gradedStudents.students.every(
        (gradedStudent) => gradedStudent.assignmentId !== student?.assignmentId,
      )
    ) {
      const selectedStudent = gradedStudents.students.find(
        (gradedStudent) => gradedStudent.id === student?.id,
      );
      if (selectedStudent) {
        setStudent(selectedStudent);
      } else {
        setStudent(gradedStudents.students[0]);
      }
    }
  }, [gradedStudents, setStudent, student]);

  const studentOptions = gradedStudents?.students.map((student) => {
    return {
      label: student.name.fullName(),
      value: student.id,
    };
  });

  return (
    <Filters>
      <FilterGroup>
        <SlidingToggleButton
          data-testid="scopeButton"
          onClick={() => {
            updateContext({
              studentMode: !gradeContextDto.isStudentMode,
            });
          }}
          isToggled={gradeContextDto.isStudentMode}
          defaultText={t("writingTaskGradeReport.groupFilter")}
          toggledText={t("writingTaskGradeReport.studentsFilter")}
        />
        {gradeContextDto.isStudentMode &&
          (studentOptions ? (
            <StudentDropdown
              onChange={(value) => selectStudent(value)}
              options={studentOptions}
              name="selectedStudent"
              value={student?.id}
            />
          ) : (
            <StudentDropdownSkeletonContainer>
              <Skeleton height="100%" />
            </StudentDropdownSkeletonContainer>
          ))}
      </FilterGroup>

      <FilterGroup>
        <SlidingToggleButton
          onClick={() => {
            updateContext({
              letterMode: !gradeContextDto.isLetterMode,
            });
          }}
          isToggled={!gradeContextDto.isLetterMode}
          defaultText={t("writingTaskGradeReport.gradeLetterFilter")}
          toggledText={t("writingTaskGradeReport.gradePercentFilter")}
        />
        <EvaluatedCriteriaDropdown
          value={gradeContextDto.selectedCriteria}
          onChange={(value) => {
            updateContext({
              criteria: value!,
            });
          }}
          criterion={sortedGrades?.map((x) => x.criterion) ?? []}
        />
      </FilterGroup>
    </Filters>
  );
};

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StudentDropdown = styled(Dropdown)`
  width: 200px;
`;

const StudentDropdownSkeletonContainer = styled.div`
  height: 44px;
  width: 200px;
`;
