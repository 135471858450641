import { useParams } from "react-router-dom";

import { ReadOnlyUserProvider } from "@/application/ui/context/ReadOnlyUserContext.tsx";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage.tsx";
import { AssignmentsFoundRevisionPage } from "@/application/ui/pages/Revision/RevisionStates/AssignmentsFoundRevisionPage.tsx";
import { NoAssignmentsFoundRevisionPage } from "@/application/ui/pages/Revision/RevisionStates/NoAssignmentsFoundRevisionPage.tsx";
import { useWritingTaskWithAssignments } from "@/application/ui/pages/Revision/useWritingTaskWithAssignments.ts";

import { AssignmentsProvider } from "./RevisionStates/AssignmentsProvider";

export const RevisionPage = () => {
  const { writingTaskId, studentId } = useParams();

  const { isLoading, writingTaskWithAssignments, refetch } =
    useWritingTaskWithAssignments(writingTaskId ?? "");

  let children;
  if (isLoading) {
    children = <LoadingPage />;
  } else if (
    !isLoading &&
    writingTaskWithAssignments?.assignments.length === 0
  ) {
    children = <NoAssignmentsFoundRevisionPage />;
  } else if (writingTaskWithAssignments) {
    children = (
      <AssignmentsProvider>
        <AssignmentsFoundRevisionPage
          writingTaskWithAssignments={writingTaskWithAssignments}
          studentId={studentId}
          onAssignmentUpdated={() => void refetch()}
        />
      </AssignmentsProvider>
    );
  }

  return (
    <ReadOnlyUserProvider readOnlyUser={false}>{children}</ReadOnlyUserProvider>
  );
};
