import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { WritingTaskContextMenu } from "@/application/ui/pages/Dashboard/components/TeacherWritingTaskCard/Header/ContextMenu/WritingTaskContextMenu.tsx";

import { useFlags } from "../../../../../App/FlagsProvider.tsx";
import { useTeacherDashboard } from "../../../../useTeacherDashboard.ts";
import { Delete } from "./Actions/Delete";
import { Hide } from "./Actions/Hide.tsx";
import { Print } from "./Actions/Print.tsx";
import { SeeStudentsList } from "./Actions/SeeStudentsList";
import { SendResults } from "./Actions/SendResults";
import { SubmitText } from "./Actions/SubmitText.tsx";
import { Unhide } from "./Actions/Unhide.tsx";
import { UpdateDraftWritingTask } from "./Actions/UpdateDraftWritingTask";
import type { ContextMenuAction } from "./MenuAction.tsx";

export interface ContextMenuProps {
  homeroomId: string;
  writingTask: TeacherWritingTask;
}

export const ContextMenuFactory = ({ writingTask }: ContextMenuProps) => {
  const {
    onDeleteDraftWritingTask,
    onOpenGradePanel,
    homeroomName,
    selectedHomeroomId,
    openUpdateForDraft,
    onPrintTaskAssignments,
    onSubmitTextAssignment,
  } = useTeacherDashboard();

  let actions: ContextMenuAction[] = [];
  const demoActions: ContextMenuAction[] = [];
  const { printPdf, demo } = useFlags();

  switch (writingTask.typeStatus) {
    case "open":
    case "closed":
      actions = [
        SendResults(writingTask),
        SeeStudentsList(writingTask, (task) =>
          onOpenGradePanel({ id: task.id, name: task.name }, homeroomName),
        ),
        Hide(writingTask),
      ];

      if (printPdf) {
        actions.push(
          Print(
            () => onPrintTaskAssignments(writingTask.id),
            writingTask.status,
          ),
        );
      }

      if (demo) {
        demoActions.push(
          SubmitText(
            () => onSubmitTextAssignment(writingTask.id),
            writingTask.status,
            writingTask.id,
          ),
        );
      }

      return (
        <WritingTaskContextMenu
          actions={actions}
          demoActions={demoActions}
          writingTask={writingTask}
        />
      );
    case "masked":
      actions = [
        SendResults(writingTask),
        SeeStudentsList(writingTask, (task) =>
          onOpenGradePanel({ id: task.id, name: task.name }, homeroomName),
        ),
        Unhide(writingTask),
      ];
      return (
        <WritingTaskContextMenu actions={actions} writingTask={writingTask} />
      );
    case "draft":
      actions = [
        UpdateDraftWritingTask(writingTask, () =>
          openUpdateForDraft(
            { id: selectedHomeroomId, name: homeroomName },
            writingTask.id,
          ),
        ),
        Delete(() => onDeleteDraftWritingTask(writingTask.id)),
      ];
      return (
        <WritingTaskContextMenu actions={actions} writingTask={writingTask} />
      );
    default:
      break;
  }
};
