import {
  Category,
  CategoryGroups,
  RevisionCategoryGroups,
} from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ErrorCategorySummary } from "@/application/ui/pages/Revision/components/Sidebar/ErrorSummary/ErrorCategorySummary.tsx";
import { SidebarBox } from "@/application/ui/pages/Revision/components/Sidebar/SidebarBox.tsx";
import type { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { CommentsSummary } from "../CommentsSummary";
import {
  CategoryVisibilityIcon,
  CommentsVisibilityIcon,
  GroupVisibilityIcon,
  RevisionVisibilityIcon,
} from "./RevisionVisibilityIcons";

type ErrorSummaryProps = {
  revisedAssignmentContentState: RevisedAssignmentContentState;
  commentsCount: number;
  pdf?: boolean;
};

export const ErrorSummary = ({
  revisedAssignmentContentState,
  commentsCount,
  pdf = false,
}: ErrorSummaryProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <SidebarBox variant="small">
        <GradeSection>
          <ErrorCategorySummary
            key={Category.VOCABULARY}
            count={revisedAssignmentContentState.countPenalizedErrorsForCategory(
              Category.VOCABULARY,
            )}
            category={Category.VOCABULARY}
          >
            {!pdf && CategoryVisibilityIcon(Category.VOCABULARY)}
          </ErrorCategorySummary>
        </GradeSection>
      </SidebarBox>

      <SidebarBox
        title={t("errorSummarySyntaxHeader")}
        action={
          !pdf &&
          GroupVisibilityIcon(
            RevisionCategoryGroups.SYNTAX,
            t("errorSummarySyntaxHeader"),
          )
        }
        variant="small"
      >
        <GradeSection>
          {CategoryGroups.SYNTAX.map((category) => (
            <ErrorCategorySummary
              key={category}
              count={revisedAssignmentContentState.countPenalizedErrorsForCategory(
                category,
              )}
              category={category}
            >
              {!pdf && CategoryVisibilityIcon(category)}
            </ErrorCategorySummary>
          ))}
        </GradeSection>
      </SidebarBox>

      <SidebarBox
        title={t("errorSummaryOrthographyHeader")}
        action={
          !pdf &&
          GroupVisibilityIcon(
            RevisionCategoryGroups.ORTHOGRAPHY,
            t("errorSummaryOrthographyHeader"),
          )
        }
        variant="small"
      >
        <GradeSection>
          {CategoryGroups.ORTHOGRAPHY.map((category) => (
            <ErrorCategorySummary
              key={category}
              count={revisedAssignmentContentState.countPenalizedErrorsForCategory(
                category,
              )}
              category={category}
            >
              {!pdf && CategoryVisibilityIcon(category)}
            </ErrorCategorySummary>
          ))}
        </GradeSection>
      </SidebarBox>

      <SidebarBox variant="small">
        <GradeSection>
          <ErrorCategorySummary
            key={Category.UNPENALIZED}
            count={revisedAssignmentContentState.countUnpenalizedErrors()}
            category={Category.UNPENALIZED}
          >
            {!pdf && CategoryVisibilityIcon(Category.UNPENALIZED)}
          </ErrorCategorySummary>
        </GradeSection>
      </SidebarBox>

      <SidebarBox variant="small">
        <GradeSection>
          <CommentsSummary count={commentsCount}>
            {!pdf && CommentsVisibilityIcon()}
          </CommentsSummary>
        </GradeSection>
      </SidebarBox>
      {!pdf && (
        <RevisionRow>
          <span>{RevisionVisibilityIcon()}</span>
          <span> {t("hideCorrection")}</span>
        </RevisionRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const GradeSection = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 8px;
`;

const RevisionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 8px;
  padding: 10px 16px;
`;
