import { useContext } from "react";

import { TeacherDashboardContext } from "./TeacherDashboardProvider";

export const useTeacherDashboard = (): TeacherDashboardContext => {
  const context = useContext(TeacherDashboardContext);

  if (!context) {
    throw new Error("TeacherDashboardProvider must be instanciated before use");
  }

  return context;
};
