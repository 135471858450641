import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import { useLoggedUser } from "@/application/authentication/hooks/useLoggedUser.ts";
import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import { Avatar } from "@/application/ui/lib/avatar/Avatar.tsx";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { ReactComponent as BarChartIcon } from "@/assets/bar-chart-square.svg";
import { ReactComponent as BodyOutlineIcon } from "@/assets/body-outline.svg";
import { ReactComponent as HomeIcon } from "@/assets/home-icon.svg";
import { ReactComponent as LogoutIcon } from "@/assets/logout.svg";
import { ReactComponent as PlusSquareIcon } from "@/assets/plus-square.svg";
import { ReactComponent as SmallLogo } from "@/assets/small-logo.svg";
import { ReactComponent as BilanIcon } from "@/assets/speedometer.svg";
import { ReactComponent as WritingTaskIcon } from "@/assets/writing-task-icon.svg";
import { DeveloperOnly } from "@/development/component/DeveloperOnly.tsx";

import { AdminOnly } from "../../../development/component/AdminOnly";
import { useFlags } from "../pages/App/FlagsProvider";
import { theme } from "../theme/theme";

export const NavBar = () => {
  const { t } = useTranslation();
  const { logout, user } = useLoggedUser();
  const [contextualMenuOpen, setContextualMenuOpen] = useState(false);
  const queryClient = useQueryClient();
  const { demo } = useFlags();

  const { mutate: switchRole } = useMutation({
    mutationFn: (role: "admin" | "teacher") =>
      AuthenticatedHttpClient.put(`/users/switchRole/${role}`),
    onSuccess: () => queryClient.invalidateQueries(),
  });

  if (window.location.pathname.includes("etudiant/composition")) {
    return <></>;
  }

  return (
    <Nav>
      <Upper>
        <NavLink to="" end>
          <LogoContainer>
            <SmallLogo width={22} height={22} />
          </LogoContainer>
        </NavLink>
        {(user.isTeacher() || user.isStudent()) && (
          <NavIconButton to="" end>
            <WritingTaskIcon />
          </NavIconButton>
        )}
        {user.isTeacher() && (
          <NavIconButton to="bilan" end>
            <BilanIcon />
          </NavIconButton>
        )}
        <AdminOnly>
          <NavIconButton to="revision-request">
            <BodyOutlineIcon />
          </NavIconButton>
          <NavIconButton to="import-users">
            <BarChartIcon />
          </NavIconButton>
          {demo && (
            <NavIconButton to="invite-demo">
              <PlusSquareIcon />
            </NavIconButton>
          )}
        </AdminOnly>
      </Upper>
      <Popover
        isOpen={contextualMenuOpen}
        positions="right"
        align="end"
        padding={16}
        onClickOutside={() => setContextualMenuOpen(false)}
        content={
          <ContextualMenu>
            <ContextMenuButton
              onClick={logout}
              hierarchy="neutral"
              variant="text"
            >
              <LogoutIcon />
              {t("logout")}
            </ContextMenuButton>
            <DeveloperOnly>
              {!user.isAdmin() && (
                <ContextMenuButton
                  hierarchy="neutral"
                  variant="text"
                  onClick={() => switchRole("admin")}
                >
                  <HomeIcon />
                  Devenir administrateur
                </ContextMenuButton>
              )}
              {user.isAdmin() && (
                <ContextMenuButton
                  hierarchy="neutral"
                  variant="text"
                  onClick={() => switchRole("teacher")}
                >
                  <HomeIcon />
                  Devenir professeur
                </ContextMenuButton>
              )}
            </DeveloperOnly>
          </ContextualMenu>
        }
      >
        <button
          onClick={() => setContextualMenuOpen(!contextualMenuOpen)}
          style={{ cursor: "pointer" }}
        >
          <Avatar user={user} />
        </button>
      </Popover>
    </Nav>
  );
};

const ContextMenuButton = styled(Button)`
  justify-content: flex-start;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${theme.colors.gray[800]};
  padding: 24px 12px;
`;

const LogoContainer = styled.div({
  backgroundColor: theme.colors.primary["500"],
  borderRadius: theme.radius.round,
  display: "flex",
  width: 32,
  height: 32,
  alignItems: "center",
  justifyContent: "center",
});

const Upper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const NavIconButton = styled(NavLink)`
  padding: 8px;
  display: flex;
  color: ${theme.colors.gray[300]};
  border-radius: ${theme.radius.small};
  cursor: pointer;
  &.active {
    background-color: ${theme.colors.gray[700]};
  }
`;

const ContextualMenu = styled.section`
  ${`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${theme.radius.xsmall};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.large};
  width: fit-content;
  border: ${theme.borders.primary};
`}
`;
