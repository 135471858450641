import { t } from "i18next";
import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import styled from "styled-components";

import { ReactComponent as TrashIcon } from "@/assets/trash.svg";

import { Button } from "../../../lib/button/Button";
import { BaseModal } from "../../../lib/form/BaseModal";
import { FeaturedIcon } from "../../../lib/icon/FeaturedIcon";
import { LoadingSpinner } from "../../../lib/spinner/LoadingSpinner";
import { theme } from "../../../theme/theme";
import { useDeleteDraftWritingTask } from "../hooks/useDeleteDraftWritingTask";
import { useLinkedDraftWritingTask } from "../hooks/useLinkedDraftWritingTasks";

export interface DeleteDraftWritingTaskModalRef {
  display: (taskId: string) => void;
}

export const DeleteDraftWritingTaskModal = React.forwardRef(
  (_, ref: Ref<DeleteDraftWritingTaskModalRef>) => {
    const [open, isOpen] = useState<boolean>(false);
    const [writingTaskId, setWritingTaskId] = useState<string | undefined>(
      undefined,
    );

    const { deleteDraft } = useDeleteDraftWritingTask();
    const { linkedTasks, isLoading } = useLinkedDraftWritingTask(writingTaskId);

    useImperativeHandle(ref, () => ({
      display: (taskId: string) => {
        setWritingTaskId(taskId);
        isOpen(true);
      },
    }));

    return (
      <BaseModal
        open={open}
        icon={
          <FeaturedIcon color="error" size={20}>
            <TrashIcon />
          </FeaturedIcon>
        }
        onClose={() => isOpen(false)}
        title={t("draftWritingTask.deletionModal.title")}
        description={
          <>
            <span>{t("draftWritingTask.deletionModal.description")}</span>
            <LinkedGroupList>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                linkedTasks?.groups.map((x, index) => (
                  <LinkedGroup key={index}>{x}</LinkedGroup>
                ))
              )}
            </LinkedGroupList>
            <span>{t("draftWritingTask.deletionModal.confirmation")}</span>
          </>
        }
      >
        <DeleteButton
          hierarchy="neutral"
          onClick={() => {
            isOpen(false);
          }}
        >
          {t("cancel")}
        </DeleteButton>
        <DeleteButton
          hierarchy="error"
          variant="contained"
          onClick={() => {
            isOpen(false);
            deleteDraft(writingTaskId!);
          }}
        >
          {t("draftWritingTask.deletionModal.delete")}
        </DeleteButton>
      </BaseModal>
    );
  },
);

const LinkedGroupList = styled.div`
  display: flex;
  gap: 16px;
`;

const LinkedGroup = styled.div`
  padding: 2px 8px;
  border-radius: ${theme.radius.xlarge};
  background-color: ${theme.colors.gray[100]};
  ${theme.typography.sm.medium};
`;

const DeleteButton = styled(Button)`
  width: auto;
`;
