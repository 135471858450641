import { useQuery } from "@tanstack/react-query";

import { TeacherHomeroomsClient } from "@/application/client/TeacherHomeroomsClient";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useTeacherHomerooms = () => {
  const {
    data: homerooms,
    isError,
    isLoading,
  } = useQuery({
    queryKey: TeacherQueryKeys.homerooms,
    queryFn: () => TeacherHomeroomsClient.fetchHomerooms(),
    retry: false,
  });
  return { homerooms, isError, isLoading };
};
