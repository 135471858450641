import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";

import { AssignmentStatus } from "@/application/domain/Assignment.ts";
import { TextStyle } from "@/application/domain/TextStyle.ts";
import { WritingTaskAssignmentStatuses } from "@/application/domain/WritingTask/WritingTaskAssignmentStatuses.ts";

export const commonFr = {
  original: "Original",
  revised: "Corrigé",
  collapseTile: "Réduire la fenêtre",
  expandTile: "Étendre la fenêtre",
  clear: "Réinitialiser",
  delete: "Supprimer",
  submit: "Soumettre",
  [Category.GRAMMAR]: "Accord",
  [Category.PUNCTUATION]: "Ponctuation",
  [Category.SPELLING]: "Orthographe",
  [Category.SYNTAX]: "Syntaxe",
  [Category.UNPENALIZED]: "Non-comptabilisées",
  [EvaluationCriterion.ADAPTATION]: "Adaptation",
  [EvaluationCriterion.COHERENCE]: "Cohérence",
  [EvaluationCriterion.VOCABULARY]: "Vocabulaire",
  [EvaluationCriterion.ORTHOGRAPHY]: "Orthographe",
  errorWithCount_one: "{{count}} faute",
  errorWithCount_other: "{{count}} fautes",
  commentWithCount_zero: "{{count}} commentaire",
  commentWithCount_one: "{{count}} commentaire",
  commentWithCount_other: "{{count}} commentaires",
  hideCorrection: "Masquer les corrections",
  words: "mots",
  totalWordCount: "{{count}} mot",
  totalWordCount_other: "{{count}} mots",
  totalSentenceCount: "{{count}} phrase",
  totalSentenceCount_other: "{{count}} phrases",
  gradingScale: "Grille de correction",
  gradingScaleDetails: "Grille détaillée",
  gradingScaleDetailsTooltip:
    "L'ensemble des grilles seront disponible très bientôt.",
  errorSummaryOrthographyHeader: "Orthographe",
  errorSummarySyntaxHeader: "Syntaxe",
  [TextStyle.ARGUMENTATIVE]: "argumentatif",
  [TextStyle.DESCRIPTIVE]: "descriptif",
  [TextStyle.EXPLICATION]: "explicatif",
  [TextStyle.INFORMATIONAL]: "informatif",
  [TextStyle.NARRATIVE]: "narratif",
  [TextStyle.POETRY]: "poétique",
  [AssignmentStatus.CORRECTED]: "Corrigé par Émilia",
  [AssignmentStatus.WAIT_FOR_CORRECTION]: "Soumis",
  [AssignmentStatus.GRADED]: "Validé par l'enseignant(e)",
  [AssignmentStatus.WRITING_IN_PROGRESS]: "En rédaction",
  [AssignmentStatus.CORRECTION_REJECTED]: "Refusée par Émilia",
  [AssignmentStatus.NOT_SUBMITTED]: "Non débuté",
  [AssignmentStatus.NULL]: "-",
  calculatedGradeTooltipMessage:
    "Cette cote est calculée automatiquement par Émilia",
  confirmGrade: "Confirmer la note",
  confirmGradeTooltip:
    "Vous devez choisir une cote pour tous les critères avant de confirmer le résultat de l’étudiant.",
  save: "Sauvegarder",
  cancel: "Annuler",
  edit: "Modifier",
  confirmGradeFail:
    "Une erreur est survenue! Votre révision n'a pas pu être sauvegardée.",
  confirmGradeSuccess: "Votre révision a été enregistrée avec succès!",
  obtainedGrade: "Note obtenue",
  noTextFoundMessage: "Aucun texte n'a été trouvé",
  noAssignmentsYet:
    "Aucune rédaction n'est prête à être évaluée pour le moment. Veuillez revenir un peu plus tard!",
  urlAddedToClipboardMessage: "L'URL a été copié dans le presse-papiers",
  sendAssignmentFail:
    "Une erreur est survenue! Votre composition n'a pas pu être enregistrée.",
  sendAssignmentSuccess: "Votre composition a été enregistrée avec succès!",
  sendAssignmentAlreadySubmitted:
    "Votre composition a été enregistrée et soumise par votre enseignant.",
  writingAssignmentPage: {
    saveDisclaimer: "* Le texte est sauvegardé automatiquement en temps réel.",
    textPlaceholder: "Texte...",
    sidePanelTitle: "Consignes",
    wordCount: "Mots saisis",
    limmit: "Limite de mots",
    wordsLeft: "Mots restants",
    securityModalTitle: "Un évènement a été détecté",
    securityModalContent:
      "Faites signe à votre enseignant(e) pour débloquer votre copie et poursuivre votre rédaction.",
    securityModalAction: "Débloquer la copie",
    closeAssignmentModalTitle: "Cette rédaction a été fermée",
    closeAssignmentModalContent:
      "Ta rédaction est maintenant fermée. Emilia l'a sauvegardée, tout est en sécurité !",
    closeAssignmentModalAction: "Retourner au tableau de bord",
  },

  commentTextAreaPlaceholder: "Écrivez votre commentaire ici",
  comment: "Commentaire écrit",
  noComment: "Aucun commentaire",
  displayErrorMarks: "Afficher les marques",
  hideErrorMarks: "Cacher les marques",
  accountedError: "Erreur comptabilisée",
  unAccountedError: "Erreur non comptabilisée",
  welcome: "Bienvenue sur Émilia",
  welcomeSubMessage:
    "s'occupe de la correction pour vous laisser tout le loisir d’inspirer vos scripteurs à se dépasser!",
  signInWithAzure: "Connexion avec Azure",
  logout: "Déconnexion",
  write: "Rédiger",
  startRecording: "Débuter l'enregistrement",
  startRecordingButton: "Bouton de début d'enregistrement",
  stopRecording: "Terminer l'enregistrement",
  audioComment: "Commentaire audio",
  recording: "En cours d'enregistrement",
  playRecord: "Jouer l'enregistrement",
  stopRecord: "Arrêter l'enregistrement",
  audioIndicator: "Enregistrement disponible",
  deleteAudio: "Retirer l'enregistrement",
  recorder: "Enregistreur",
  dashboard: "Tableau de bord",
  noWritingTaskTitle: "Créer votre première tâche d'écriture!",
  noWritingTaskSubtitle:
    "Emil-IA est ravi de pouvoir vous aider dans la création et la correction de vos épreuves.",
  createWritingTask: "Créer une tâche d’écriture",
  writingTaskCreation: "Création d'une tâche d'écriture",
  writingTaskUpdate: "Mise à jour d'une tâche d'écriture",
  create: "Créer",
  createResource: "Créer",
  update: "Mettre à jour",
  taskTitle: "Titre de la tâche",
  instruction: "Consigne",
  HomeroomGroups: "Sélectionner le(s) groupe(s)",
  HomeroomGroupsDropdown: "Groupe: ",
  failToCreateWritingTaskGroupNotSelected: "Il faut choisir au moins un groupe",
  failToCreateWritingTask:
    "La tâche n'a pu être créée. Veuillez réessayer plus tard.",
  failToUpdateWritingTask:
    "La tâche n'a pu être modifiée. Veuillez réessayer plus tard.",
  writingTaskCreatedSuccessfully:
    'La tâche "{{title}}" a été créée avec succès.',
  writingTaskUpdatedSuccessfully:
    'La tâche "{{title}}" a été mise à jours avec succès.',
  includeHiddenTask: "Voir les tâches masquées",
  newTask: "Nouvelle tâche d'écriture",
  sections: {
    newSection: "Nouvelle section",
    unclassified: "Non-classés",
    createSectionPlaceholder: "Nommer la section",
    rename: "Renommer",
    clear: "Effacer",
  },
  taskInstructions: "Consigne donnée aux élèves",
  taskInstructionsPlaceholder:
    "Mentionnez ici le sujet de même que la consigne demandée aux élèves afin qu’Emilia puisse bien comprendre le contexte d'écriture (critère 1: Adaptation à la situation d'écriture).",
  redactionType: {
    argumentative: "Argumentatif",
    explicative: "Explicatif",
    descriptive: "Descriptif",
    justificative: "Justificatif",
    narrative: "Narratif",
  },
  redactionTypeInput: "Type de rédaction",
  sectionInput: "Choisir une section",
  gradingScaleInput: "Choix de la grille",
  gradingScaleOption: {
    secondary_one: "Secondaire 1",
    secondary_two: "Secondaire 2",
    secondary_three: "Secondaire 3",
    secondary_four: "Secondaire 4",
    secondary_five: "Secondaire 5",
    secondary_five_ministry: "Secondaire 5, examen du ministère",
    third_year: "3e année",
    fourth_year: "4e année",
    fifth_year: "5e année",
    sixth_year: "6e année",
  },
  CriteriaChoice: "Choix des critères",
  EvaluationCriterias: {
    title: {
      C1: "Critère 1",
      C2: "Critère 2",
      C3: "Critère 3",
      C4: "Critère 4",
      C5: "Critère 5",
    },
    description: {
      C1: ": Adaptation à la situation d’écriture",
      C2: ": Cohérence",
      C3: ": Vocabulaire",
      C4: ": Syntaxe et ponctuation",
      C5: ": Orthographe (gramm et d'usage)",
    },
  },
  teacherWritingTaskStatus: {
    writing_in_progress: "Rédaction en cours",
    ai_correction_in_progress: "Emilia Corrige",
    ready_to_revise: "Prêt à valider",
    revision_in_progress: "Validation en cours",
    ready_to_publish: "Prêt à envoyer",
    done: "Terminé",
  },
  demoLimit: "Vous avez atteint la limite de votre compte démo",
  teacherWritingTaskCardAction: {
    demoOptions: "Options de démo",
    submit_text: "Soumettre un texte",
    print_assignments: "Générer les PDFs",
    update_writing_task: "Modifier la tâche d’écriture",
    update_writing_task_tooltip:
      "La tâche ne peut pas être modifiée dû à la publication des résultats",
    send_results: "Envoyer les résultats",
    see_copies: "Voir les Copies",
    closeWritingTask: "Fermer la tâche",
    openWritingTask: "Ouvrir la tâche",
    archive: "Archiver",
  },
  teacherWritingTaskCardInformations: {
    type: "Type",
    gradingScale: "Grille",
    wordsCount: "Nbr. de mots",
  },
  studentWritingTaskCardInformations: {
    redactionType: "Type de rédaction",
    taskType: "Type de tâche",
    wordsCount: "Nbr. de mots",
  },
  studentWritingTaskStatus: {
    wait_for_revision: "En attente de correction",
    reviewed: "Corrigé",
    writing_in_progress: "Redaction en cours",
    todo: "À faire",
  },
  writingTaskPublishSucceed: "Les notes ont bien été envoyé",
  printTaskAssignments: {
    action: "Imprimer",
    title: "Imprimer les copies",
    allStudents: "Tous les élèves",
    failure:
      "Les copies n'ont pas pu être imprimées. Veuillez réessayer plus tard.",
  },
  submitTextAssignment: {
    tooManyWords:
      "Votre rédaction dépasse la limite de {{max}} mots. Veuillez réduire le nombre de mots pour soumettre.",
    action: "Soumettre",
    title: "Ajouter votre propre texte à Emilia",
    failure: "Le texte n'a pas pu être soumis. Veuillez réessayer plus tard.",
    success: {
      title: "Votre texte à bien été soumi.",
      description:
        "La correction par l'IA peut prendre quelque minutes. Vous verrez votre texte dans la zone bleu lorsqu'il sera prêt.",
    },
  },
  failToPublishWritingTask:
    "Les notes n'ont pas pu être envoyé. Veuillez réessayer plus tard.",
  writingTaskGradeSummary: "Résultats de la classe",
  seeStudentList: "Voir la liste de la classe",
  seeUnlockCode: "* * * *",
  grades: "Résultats",
  maintenance:
    "Emilia est présentement en maintenance et risque d'être temporairement indisponible.",
  securedSummativeMode: {
    disclaimer: "Mode sécurisé activé sur cette tâche.",
    tooltip:
      "Un avertissement apparaîtra si Emilia détecte un changement sur cette page. Votre enseignant(e) devra insérer un code pour vous permettre de continuer.",
  },
  summativeDisclaimer:
    "Si Emilia détecte un changement pendant la rédaction de l'élève, un avertissement lui sera affiché. Vous devrez insérer un code unique pour permettre à l'élève de continuer sa rédaction.",
  securedMode: "Mode sécurisé activé",
  securedModeTooltip: "Cette tâche est sécurisée",
  securedModeDisclosure: {
    understood: "J'ai compris",
    description1:
      "Pour cette rédaction, nous avons activé le mode sécurisé afin de limiter l'usage d'outils non autorisés.",
    description2:
      "Si vous quittez cette page de rédaction, un avertissement apparaîtra. Vous aurez alors besoin d'un code de votre enseignant(e) pour continuer.",
    description3: "Restez concentré(e) et bonne chance pour votre rédaction !",
  },
  studentSummaryContextMenu: {
    unlockAssignment: "Débloquer la rédaction",
    publishAssignment: "Envoyer le résultat",
    see_copie: "Voir la rédaction",
  },
  unlockAssignment: "Débloquer la rédaction",
  unlockAssignmentSucceed: "La copie a bien été débloquée",
  failToUnlockAssignment:
    "La copie n'a pas pu être débloquée. Veuillez réessayer plus tard.",
  moreOptions: "Plus d'options",
  moreSectionOptions: "Plus d'options de section",
  taskType: {
    exam: "Examen",
    summative: "Sommatif",
    formative: "Formatif",
    exercise: "Exercice",
  },
  taskTypeInput: "Type de la tâche",
  previousStudent: "Étudiant précédent",
  nextStudent: "Étudiant suivant",
  viewCopy: "Consulter la copie",
  writingAssignmentConfirmationDialog: {
    title: "Confirmation d’envoi",
    description:
      "Veuillez noter que vous ne pourrez pas soumettre de nouveau un texte pour cette tâche une fois l’envoi confirmé. Êtes-vous sûr(e) de vouloir procéder ?",
  },
  closeWritingTaskConfirmationDialog: {
    title: "Certaines rédactions n'ont pas été soumises ou débutées.",
    content: {
      closeAndSubmit:
        "<b>1. Fermer et soumettre</b> : Les copies en cours de rédaction seront soumises pour correction, et l'accès sera fermé pour les élèves qui n'ont pas débuté.",
      close:
        "<b>2. Fermer</b> : Fermer temporairement l'accès, afin que les élèves puissent reprendre plus tard.",
      choices: "En fermant l'accès à la rédaction, vous avez deux choix :",
    },
    actions: {
      closeAndSubmit: "Fermer et soumettre",
      close: "Fermer",
    },
  },
  writingTaskModificationDialog: {
    title: "Confirmer la modification des critères",
    description:
      "En mettant à jour les critères, vous devez à nouveau confirmer la note de l'ensemble des copies. Est-ce bien ce que vous souhaitez?",
  },
  draftWritingTaskModificationDialog: {
    title: "Confirmer la modification",
    description: "Voulez-vous vraiment modifier les informations de la tâche?",
  },
  yes: "Oui",
  prenomNom: "Prénom, Nom",
  nomPrenom: "Nom, Prénom",
  hashtag: "#",
  status: "Statut",
  progressBarName: "barre de progression des copies de la tâche d'écriture",
  [WritingTaskAssignmentStatuses.AI_ERROR]: "Erreur de l'IA",
  [WritingTaskAssignmentStatuses.CORRECTED]: "Corrigé",
  [WritingTaskAssignmentStatuses.VALID]: "Validé",
  [WritingTaskAssignmentStatuses.REDACTING]: "En rédaction",
  [WritingTaskAssignmentStatuses.SUBMITTED]: "Soumis",
  [WritingTaskAssignmentStatuses.NOT_SUBMITTED]: "Non débuté",
  writingTaskNameOrder: {
    lastName: "Nom, Prénom",
    firstName: "Prénom, Nom",
  },
  orderBy: "Trier par",
  student_zero: "{{count}} élève",
  student_one: "{{count}} élève",
  student_other: "{{count}} élèves",
  published: "Publié",
  recognize: "Comptabiliser",
  notEvaluated: "Ce critère n’est pas évalué",
  atLeastOneCriteria: "Au moins un critère doit être sélectionné",
  numberOfWords: "Nombre de mots",
  resultsByCriteria: "Résultats par critères",
  relativePercentage: "% relatif",
  grade: "Cotes",
  accessDashboard: "Bilan",
  writingTaskGradeReport: {
    openAssignment: "Voir la copie",
    closeAssignment: "Fermer la copie",
    groupFilter: "Classe",
    studentsFilter: "Élèves",
    gradePercentFilter: "%",
    gradeLetterFilter: "ABC",
    assignmentFilter: "Rédaction: ",
    to: "à",
    globalGradeDistribution: "Classement global",
    writingTaskInformation: "Information de la rédaction",
    taskWritingContext: "Contexte d'écriture",
    close: "Fermer",
    showStudents: "Afficher les élèves",
    hideStudents: "Masquer les élèves",
    criterias: "Critères",
    all: "Tous",
    Subcrietria: "Sous-Critères",
    Ranking: "Classement",
    noErrorsBlankslate: "Wow ce critère ne contient aucune erreur!",
    countedCriteria: "Critères comptabilisés",
    evaluation: "Évaluation",
    median: "Médiane",
  },
  subcriteriaClassment: {
    title: "Classement sous-critères",
    error_zero: "{{count}} erreur",
    error_one: "{{count}} erreur",
    error_other: "{{count}} erreurs",
    popup: {
      title: "Explication du sous-critère",
    },
  },
  loading: "Chargement...",
  errorsMode: "Erreurs",
  closePopup: "Fermer le pop-up",
  importFailure: "Échec de l'importation",
  importSuccessful: "Importation complétée avec succès",
  timeoutPage: {
    title: "Nous sommes incapable de générer la page suivante",
    subtitle: "Veuillez rafraichir la page ou réessayer plus tard.",
  },
  noGroupsPage: {
    title: "Votre compte n'est assigné à aucun groupe.",
    subtitle: "Veuillez contacter votre administrateur.",
  },
  demo: {
    title: "Importer liste démo",
    list: "Liste",
    import: "Importer",
  },
  correctMode: "Corriger",
  commentMode: "Commenter",
  correctModeStudent: "Voir les corrections",
  commentModeStudent: "Voir les commentaires",
  unpenalizedMode: "Décomptabiliser",
  modify: "Modifier",
  addComment: "Ajouter un commentaire",
  addRevision: "Ajouter une correction",
  writeCommentHere: "Tapez votre commentaire ici...",
  close: "Fermer",
  commentDetails: "Détails du commentaire",
  revisionDetails: "Détails de la correction",
  comments: "Commentaires",
  unpenalizedThisError: "Décomptabiliser cette erreur",
  confirm: "Confirmer",
  confirmRevisionTooltip:
    "Veuillez sélectionner une catégorie pour confirmer l'ajout de l'erreur.",
  flag: "Signaler la copie",
  inactiveStudent: "Cet étudiant ne s’est jamais connecté",
  closeWritingTask: {
    tag: "Fermée",
    success: {
      title: "Votre rédaction à bien été fermée.",
      message: "Les élèves pourront reprendre plus tard la rédaction.",
    },
    fail: "Une erreur est survenur lors de la fermeture de la rédaction.",
  },
  openWritingTask: {
    tag: "Ouvert",
    success: {
      title: "Cette rédaction est désormais ouverte",
      message: "Les élèves peuvent reprendre la rédaction.",
    },
    fail: "Une erreur est survenur lors de l'ouverture de la rédaction.",
  },
  draftWritingTask: {
    tag: "Non-distribuée",
    deletionModal: {
      title: "Supprimer la tâche d'écriture",
      description:
        "Cette tâche est liée à plusieurs groupes. Elle sera donc supprimée dans les groupes suivants:",
      confirmation:
        "Confirmez-vous vouloir supprimer cette tâche? Cette action est irréversible",
      delete: "Supprimer la tâche",
      success: "La tâche à été supprimée.",
      fail: "Une erreur est survenue lors de la suppression de la tâche.",
    },
    menuActions: {
      delete: "Supprimer",
      edit: "Modifier la tâche",
    },
    distribute: {
      modal: {
        title: "Distribuer cette tâche",
        description:
          "Une fois que votre tâche sera distribuée, tous les élèves de votre groupe pourront débuter la rédaction.",
        boldDescription:
          "Il ne sera plus possible de modifier cette tâche après avoir confirmé.",
      },
      action: "Distribuer la tâche",
      success: "Cette rédaction est désormais visible aux étudiants",
      fail: "Une erreur est survenur lors de la publication de la rédaction.",
    },
  },
  closeAndSubmitWritingTask: {
    success: {
      title: "Vos copies ont été soumises pour correction.",
      message:
        "L'accès à la rédaction est fermé pour ceux qui n'ont pas débuté.'",
    },
    fail: "Une erreur est survenur lors de la soumision des copies et fermeture de la rédaction.",
  },

  archiveWritingTask: {
    callToAction: "Désarchivé",
  },
  hiddenWritingTask: {
    title: "Masquée",
    action: {
      hide: "Masquer la tâche",
      unhide: "Rendre visible la tâche",
    },
  },
};
