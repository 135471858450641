import { useTranslation } from "react-i18next";

import { ReactComponent as PLusIcon } from "@/assets/plus-square.svg";

import type { ContextMenuAction } from "../MenuAction";

export const SubmitText = (
  onSubmit: () => void,
  taskStatus: string,
  taskId: string,
): ContextMenuAction => {
  const { t } = useTranslation();
  const isDisabled = !["writing_in_progress"].includes(taskStatus);

  return {
    tooltipMessage: t("demoLimit"),
    label: t("teacherWritingTaskCardAction.submit_text"),
    className: isDisabled ? `disabledSubmitText${taskId}` : undefined,
    children: (
      <>
        <PLusIcon />
        {t("teacherWritingTaskCardAction.submit_text")}
      </>
    ),
    disabled: isDisabled,
    callback: onSubmit,
  };
};
